const ngModule = angular.module('ppa.widgets.spinner', []);

ngModule.component('ppaSpinner', {
    template: require('./spinner.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: [function(){

        var vm = this;

        // -- initial state

        // -- util functions

        // -- scope bindings

        // -- main

    }]
});