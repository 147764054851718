const ngModule = angular.module('ppa.widgets.edit-rent-form', [
    'ppa.services.snack'
]);

ngModule.directive('ppaEditRentForm', [function() {

    var ERROR_MESSAGES = { update: 'Update of rent failed.', delete: 'Delete of rent failed.'};
    var SUCCESS_MESSAGES = { update: 'The rent was updated.', delete: 'The rent was deleted.'};

    return {
        restrict: 'A',
        template: require('./edit-rent-form.pug'),
        scope: {
            rent: '<ppaEditRentForm',
            onUpdate: '&',
            onDelete: '&'
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['$scope', '$http', '$timeout', 'snackService', function($scope, $http, $timeout, snackService) {

            var vm = this;

            // -- initial state

            // -- util functions

            function showSuccessBanner(type) {
                snackService.success(SUCCESS_MESSAGES[type]);
            }

            function showErrorBanner(type) {
                snackService.error(ERROR_MESSAGES[type]);
            }

            // -- api

            vm.updateRent = function() {
                if(vm.form.$valid && !vm.deleted) {
                    vm.updating = true;
                    vm.onUpdate({rent: angular.copy(vm.rent)})
                    .then(function(){
                        showSuccessBanner('update');
                    }, function(){
                        showErrorBanner('update');
                    })
                    .finally(function(){
                        vm.updating = false;
                    });
                }
            };

            vm.deleteRent = function() {
                vm.deleting = true;
                vm.onDelete({
                    id: vm.rent._id
                })
                .then(function(){
                    vm.deleted = true;
                    showSuccessBanner('delete');
                }, function(){
                    showErrorBanner('delete');
                })
                .finally(function(){
                    vm.deleting = false;
                });
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
