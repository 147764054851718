
const ngModule = angular.module('ppa.services.dashboard', [
    'ppa.services.rent',
    'ppa.services.expense',
    'ppa.services.utils'
]);

ngModule.service('dashboardService', ['$q', 'rx', 'rentService', 'expenseService', 'utils', function($q, rx, rentService, expenseService, utils) {

    var api = {};

    var TENANT_ENDPOINT = '/api/tenants';

    api.getTotals = function(startDate, endDate) {

        var promises = {
            payments: rentService.getPaymentsForDateRange(startDate, endDate),
            expenses: expenseService.getExpensesForDateRange(startDate, endDate)()
        };

        return $q.all(promises).then(function(responses) {
            return {
                incomings: responses.payments.reduce(utils.sumAmounts.bind(null, 'amount'), 0),
                outgoings: responses.expenses.reduce(utils.sumAmounts.bind(null, 'amount'), 0)
            };
        });

    };


    return api;

}]);