
const ngModule = angular.module('ppa.services.preference', [
    'ppa.services.resources'
]);

ngModule.service('preferenceService', ['resources', function(resources) {

    var api = {};

    var PREFERENCE_ENDPOINT = '/api/preferences';

    function getPreferenceIdByName(name) {
        return api.getPreferences().then(function(preferences){
            return preferences[name]._id;
        });
    }

    api.getPreferences = function(){
        return resources.httpGet(PREFERENCE_ENDPOINT, null, 'preferences').then(function(preferences){
            return preferences.reduce(function(map, preference){
                map[preference.name] = preference;
                return map;
            },{});
        });
    };

    api.getPreference = function(name) {
        return this.getPreferences().then(function(preferences){
            return preferences[name].value;
        });
    };

    api.addPreference = function(preference){
        var endpoint = PREFERENCE_ENDPOINT;
        return resources.httpPost(endpoint, preference);
    };

    api.updatePreference = function(value, name){
        return getPreferenceIdByName(name).then(function(id){
            if(!id) {
                return api.addPreference({
                    value: value,
                    name: name
                });
            } else {
                var endpoint = PREFERENCE_ENDPOINT + '/'+ id;

                var payload = {
                    value: value,
                    name: name
                };

                return resources.httpPut(endpoint, payload);
            }

        }).then(function(){
            api.purgeCache();
        });
    };

    api.purgeCache = function() {
        resources.purgeCache('preferences');
    };


    return api;

}]);