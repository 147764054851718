const ngModule = angular.module('ppa.widgets.expense-edit', []);

ngModule.component('ppaExpenseEdit', {
    template: require('./expense-edit.pug'),
    bindings: {
        expense: '<'
    },
    controllerAs: 'vm',
    controller: ['rx', 'propertyService', 'clientService', 'expenseService', 'observeService', 'utils', 'dialogService', 'snackService',
    function(rx, propertyService, clientService, expenseService, observeService, utils, dialogService, snackService) {

        var vm = this;

        // -- initial state

        vm.step = '';

        // -- util functions

        function isReady() {
            return utils.isReady(
                vm.buildings,
                vm.units,
                vm.clients,
                vm.categories,
                vm.debts
            );
        }

        function initialise(){
            rx.Observable.merge(
                observeService.properties(),
                observeService.clientsAndCategories(),
                observeService.debts()
            ).subscribe(function(item){
                vm.buildings = item.buildingsMap || vm.buildings;
                vm.units = item.units || vm.units;
                vm.clients = item.clients || vm.clients;
                vm.categories = item.categoriesMap || vm.categories;
                vm.debts = item.debts || vm.debts;

                vm.ready = isReady();
            });
        }

        function handleSuccess () {
            snackService.success('Expense saved');
        }

        function handleError () {
            snackService.error('Expense could not be saved');
        }

        // -- api

        vm.deleteExpense = function() {
            return expenseService.deleteExpense(vm.expense._id).then(function(){
                snackService.success('Expense deleted');
                dialogService.close();
            }).catch(function(){
                snackService.error('Expense could not be deleted');
            });
        };

        vm.submitExpense = function(expense) {
            return expenseService.updateExpense(expense).then(handleSuccess, handleError);
        };


        // -- scope bindings


        // -- main

        initialise();
    }]
});
