const ngModule = angular.module('ppa.components.auto-complete-select', [
    'ngMaterial'
]);

ngModule.component('autoCompleteSelect', {

    template: require('./auto-complete-select.pug'),
    bindings: {
        items: '<',
        item: '<',
        displayMap: '<',
        isRequired: '<',
        isDisabled: '<',
        inputName: '@',
        placeholder: '@',
        searchText: '<',
        onSearch: '&',
        onChange: '&?'
    },
    controllerAs: 'vm',
    controller: [function() {

        var vm = this;

        // -- initial state

        // -- util functions

        function onChange(item) {
            // note early return
            if(item === vm.lastChange) {
                return;
            }

            vm.onChange({
                item: angular.copy(item)
            });

            vm.lastChange = item;
        }

        // -- api

        vm.itemChanged = function(item) {
            if(!item && vm.searchText) {
                angular.noop();
            } else if(!item) {
                vm.item = null;
            } else {
                vm.item = item;
            }

            onChange(vm.item);
        };

        vm.searchTextChange = function(search) {
            if(!search) {
                vm.item = null;
                onChange(vm.item);
            }
        };

        vm.querySearch = function(query) {
            return vm.onSearch({
                query: query
            });
        };

        //--Main

        vm.$onInit = function() {
            vm.lastChange = angular.copy(vm.item);
        };

    }]
});
