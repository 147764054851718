const ngModule = angular.module('ppa.widgets.manage-tenants', [
    'ppa.services.tenant',
    'ppa.services.observe',
    'ppa.services.dialog',
    'ppa.services.snack',
    'ppa.services.utils',
    'ppa.widgets.tenant-form'
]);

ngModule.component('ppaManageTenants', {
    template: require('./manage-tenants.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', 'tenantService', 'observeService', 'dialogService', 'snackService', 'utils', function($rootScope, $scope, tenantService, observeService, dialogService, snackService, utils){

        var vm = this;

        vm.newTenant = {};


        // -- util functions

        function isReady() {
            return utils.isReady(vm.tenants);
        }

        function closeEditor(){
            vm.editingTenant = null;
            dialogService.close();
        }

        function init(fetch) {
            return observeService.tenants().subscribe(function(res) {
                vm.tenants = res.tenants || vm.tenants;
                vm.ready = isReady();
            });
        }

        function updateTenant(tenant) {
            return tenantService.updateTenant(tenant).then(function(){
                snackService.success('Tenant saved');
            });
        }

        function addTenant(tenant) {
            return tenantService.addTenant(tenant).then(function(){
                snackService.success('Tenant saved');
                vm.newTenant = {};
            })
            .catch(function(){
                snackService.error('Unable to save tenant');
            })
            .finally(closeEditor);
        }

        function openEditTenantModal(tenant) {
            var scope = $rootScope.$new();
            scope.onSubmit = updateTenant;
            scope.tenant = angular.copy(tenant);

            var options = {
                clickClose: true,
                escapeClose: true,
                showClose: true,
                classNames: 'ppa-tenant-form',
                scope: scope
            };

            options.contents = '<ppa-tenant-form tenant="tenant" on-submit="onSubmit"></ppa-tenant-form>';

            vm.editingTenant = tenant._id;

            return dialogService.show(options).then(function(){
                vm.editingTenant = null;
            });
        }


        // -- api

        vm.clickRow = function(tenant) {
            openEditTenantModal(tenant);
        };

        // -- scope bindings

        // -- main

        vm.$onDestroy = function() {
            utils.unsubscribe(vm.stream);
        };

        vm.$onInit = function() {
            vm.stream = init();
        };





    }]
});