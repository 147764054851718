import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material';
import {DashboardGraphComponent} from './dashboard-graph';
import { HighchartsChartModule } from 'highcharts-angular';



@NgModule({
  declarations: [
    DashboardGraphComponent
  ],
  imports: [
      CommonModule,
      BrowserModule,
      MatButtonModule,
      HighchartsChartModule
  ],
  exports: [
    DashboardGraphComponent
  ]
})
export class DashboardGraphModule {
}
