
const ngModule = angular.module('ppa.services.number', []);

ngModule.service('numberService', ['$window', function ($window) {
    var api = {};


    // Caveat will return true for strings that can be parsed into numbers
    // For example '999'
    api.comprehensiveIsNumber = function (input, $window) {
        return $window.isFinite(input) &&
            !angular.isArray(input) &&
            input !== true &&
            input !== false &&
            input !== null;
    };

    api.ordinal = function (input) {
        var n = Number(input) % 100;

        if (!api.comprehensiveIsNumber(input, $window) || input === 0) {
            return '';
        }

        return (n < 11 || n > 13) ? ['st', 'nd', 'rd', 'th'][Math.min((n - 1) % 10, 3)] : 'th';
    };

    return api;
}]);
