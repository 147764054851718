const PAYMENT_DELETE_SUCCESS_MESSAGE = 'Payment deleted';
const PAYMENT_DELETE_ERROR_MESSAGE = 'Could not delete payment';

const ngModule = angular.module('ppa.widgets.payment-history-list', [
    'ppa.services.snack',
    'ppa.services.utils',
    'ppa.services.rent'
]);

ngModule.directive('ppaPaymentHistoryList', [function(){
    return {
        restrict: 'A',
        template: require('./payment-history-list.pug'),
        scope: {
            paymentHistory: '<ppaPaymentHistoryList',
            rent: '<',
            propertyName: '<',
            rentStream: '<',
            onDelete: '&'
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['rentService', 'snackService', 'utils', function(rentService, snackService, utils){

            var vm = this;

            // -- util functions

            // -- api

            vm.deletePayment = function(id) {
                vm.onDelete({
                    id: id,
                    rent: vm.rent
                }).then(function() {
                    snackService.success(PAYMENT_DELETE_SUCCESS_MESSAGE);
                }).catch(function(){
                    snackService.success(PAYMENT_DELETE_ERROR_MESSAGE);
                });
            };

            // -- main

            vm.$onDestroy = function() {
                utils.unsubscribe(vm.stream);
            };

            vm.$onInit = function() {
                vm.stream = vm.rentStream.subscribe(function(res) {
                    var report = rentService.constructRentReport(res[0], res[1]);
                    vm.paymentsMap = report.paymentsMap;
                });
            };

        }]
    };
}]);