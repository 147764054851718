const ngModule = angular.module('ppa.directives.bg-colour-content', []);

ngModule.directive('bgColourContent', [function() {
    return {
        restrict: 'A',
        scope: {
            bgColourContent: '@'
        },
        link: function(scope, element) {


            function hashCode(str) {
                var hash = 0;
                for (var i = 0; i < str.length; i++) {
                   hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }
                return hash;
            }

            function intToRGB(i){
                var c = (i & 0x00FFFFFF)
                    .toString(16)
                    .toUpperCase();

                return "00000".substring(0, 6 - c.length) + c;
            }

            function isDark(c){
                c = c.substring(1);      // strip #
                var rgb = parseInt(c, 16);   // convert rrggbb to decimal
                var r = (rgb >> 16) & 0xff;  // extract red
                var g = (rgb >>  8) & 0xff;  // extract green
                var b = (rgb >>  0) & 0xff;  // extract blue

                var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
                if (luma < 150) {
                    return true;
                }
                return false;
            }

            scope.$watch('bgColourContent', function(val){
                if(val){
                    var bgHex = '#'+intToRGB(hashCode(val.replace(/\s*/g, '')));
                    var colour = isDark(bgHex) ? '#FFFFFF' : '#000000';
                    var styles = {
                        backgroundColor: bgHex,
                        color: colour
                    };
                    element.css(styles);
                }
            });
        }
    };
}]);
