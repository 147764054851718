import * as angular from 'angular';
import {Subject, Observable, ReplaySubject} from "rxjs/Rx";
import { map, filter, switchMap, distinctUntilChanged, take } from 'rxjs/operators';

const ngModule = angular.module('ppa.services.rx', []);

ngModule.service('rx', ['$window', function ($window) {
    return {
        Subject: Subject,
        Observable: Observable,
        ReplaySubject: ReplaySubject,
        operators: {
            map: map,
            filter: filter,
            switchMap: switchMap,
            distinctUntilChanged: distinctUntilChanged,
            take: take
        }
    };
}]);
