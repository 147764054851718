import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {
  MatButtonModule,
  MatStepperModule,
  MatFormFieldModule,
  MatButtonToggleModule,
  MatInputModule
} from '@angular/material';
import {PropertyAddComponent} from './property-add';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    PropertyAddComponent
  ],
  imports: [
      CommonModule,
      BrowserModule,
      MatButtonModule,
      MatStepperModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonToggleModule
  ],
  exports: [
    PropertyAddComponent
  ]
})
export class PropertyAddModule {
}
