import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LEGACY_PROPERTY} from '../../services/ajs-upgraded-providers';
import {PropertyService} from '../../services/property/property';

@Component({
  selector: 'ppa-property-add',
  template: require('./property-add.html'),
  styles: [require('./_property-add.component.scss')]
})
export class PropertyAddComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;

  constructor(@Inject(LEGACY_PROPERTY) private authService: PropertyService, private _formBuilder: FormBuilder) {

  }

  unitArray(numberOfUnits: number): Array<undefined> {
    return Array(numberOfUnits).fill(undefined);
  }


  ngOnInit(): void {

    this.firstFormGroup = this._formBuilder.group({
      postcodeCtrl: ['', Validators.required],
      addressLineOneCtrl: ['', Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({
      bedroomsCtrl: ['', Validators.required]
    });

    this.thirdFormGroup = this._formBuilder.group({
      unitName: ['', Validators.required]
    });

  }

}



