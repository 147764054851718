const ngModule = angular.module('ppa.widgets.deposit-held', [
    'ppa.services.tenancy',
    'ppa.services.utils'
]);

ngModule.directive('depositHeld', [function() {


    return {
        restrict: 'A',
        template: require('./deposit-held.pug'),
        scope: {
            tenancy: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$rootScope', 'tenancyService', 'utils', function($scope, $rootScope, tenancyService, utils) {
            var vm = this;


             // -- initial state
            var state;

            vm.id = utils.ridGenerator(6);

            //-- util functions

            // -- api
            vm.submit = function() {
                if(vm.form.$valid) {
                    vm.tenancy.depositHeld = vm.depositHeld;
                    vm.tenancy.dateDepositHeld = vm.dateDepositHeld;
                    tenancyService.updateTenancy(vm.tenancy);
                    $rootScope.$broadcast('ppa.banner.add', 'success', 'Holding deposit saved');
                    vm.expanded = false;
                }
            };

            vm.toggleForm = function(){
                vm.expanded = !vm.expanded;
            };

            // -- main

        }]
    };
}]);
