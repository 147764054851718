const ngModule = angular.module('ppa.directives.focus-first-error', []);

ngModule.directive('focusFirstError', function() {
    return {
        restrict: 'A',
        link: function($scope, $elem) {

            // set up event handler on the form elements
            $elem.on('submit', function() {
                // all the invalid elements
                var invalids = $elem[0].querySelectorAll('input.ng-invalid, select.ng-invalid, md-select.ng-invalid, md-autocomplete.ng-invalid, md-datepicker.ng-invalid');
                // Set focus for first visible invalid item
                var i = 0;
                for (i = 0; i < invalids.length; i++) {
                    if ($(invalids[i]).is(':visible')) {
                        invalids[i].focus();
                        break;
                    }
                }
            });
        }
    };
});
