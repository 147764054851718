const ngModule = angular.module('ppa.filters.date', [
    'ppa.services.number'
]);

ngModule.filter('formatDateHuman', ['$filter', 'numberService', function ($filter, numberService) {
    return function (dateInput, format) {
        if (!dateInput) {
            dateInput = Date.now();
        }
        if (!format) {
            format = 'd MMMM yyyy';
        }

        var dateOridinal = $filter('date')(dateInput, 'd') + numberService.ordinal($filter('date')(dateInput, 'd'));

        var formatOrdinalBinding = format.replace('d', '[--]');

        var dateOrdinalBinding = $filter('date')(dateInput, formatOrdinalBinding);
        var dateLowercaseTime = dateOrdinalBinding.replace(/AM/g, 'am').replace(/PM/g, 'pm');
        var dateHumanReadable = dateLowercaseTime.replace('[--]', dateOridinal);

        return dateHumanReadable;
    };
}]);