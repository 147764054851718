const ngModule = angular.module('ppa.widgets.property-selector', [
    'ppa.services.utils',
    'ppa.components.auto-complete-select'
]);

ngModule.component('propertySelector', {
    template: require('./property-selector.pug'),
    bindings: {
        property: '=',
        units: '<',
        buildings: '<',
        propertyIdOnly: '@',
        isRequired: '<',
        isDisabled: '<',
        placeholder: '<',
        onChange: '&?'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'utils', 'propertyService', function($scope ,utils, propertyService) {

        var vm = this;

        // -- initial state

        // -- util functions

        function matchesPropertyId(id, property) {
            return id === property._id;
        }

        function unitBuilingQuery(buildings, query, unit) {
            var searchString = (unit.name + buildings[unit.building].name).toLowerCase();
            return searchString.indexOf(query.toLowerCase()) > -1;
        }

        function getInitialProperty(property) {
            if(vm.propertyIdOnly){
                return vm.units.find(matchesPropertyId.bind(this, property));
            } else {
                return vm.units.find(matchesPropertyId.bind(this, property._id));
            }

        }

        function createPropertyNameIdMap(units, unitMap, buildings) {
            var propertyName = propertyService.getPropertyName.bind(this, buildings, unitMap);
            return units.map(utils.extractId).reduce(function(dictionary, id) {
                dictionary[id] = propertyName(id);
                return dictionary;
            }, {});
        }

        function normaliseProperty(property) {
            if(angular.isObject(property) && vm.propertyIdOnly) {
                return property._id;
            } else {
                return property;
            }
        }

        function getPropertyId(property) {
            if(angular.isObject(property)) {
                return property._id;
            } else {
                return property;
            }
        }

        // -- api

        vm.change = function(property) {

            vm.property = normaliseProperty(property);

            // note early return
            if(!vm.onChange) {
                return;
            }

            vm.onChange({
                property: angular.copy(vm.property)
            });
        };

        vm.search = function(query) {
            var search = unitBuilingQuery.bind(this, vm.buildings, query);
            return vm.units.filter(search);
        };

        // -- scope bindings

        $scope.$on('ppa.property-selector.clear', function(){
            vm.searchText = '';
        });

        // -- Main

        vm.$onInit = function() {
            var unitMap = utils.createIdMap(vm.units);
            vm.displayMap = createPropertyNameIdMap(vm.units, unitMap, vm.buildings);

            vm.selectedProperty = getInitialProperty(vm.property);

            if(vm.property) {
                var id  = getPropertyId(vm.property);
                vm.searchText = propertyService.getPropertyName(vm.buildings, unitMap, id);
            }
            vm.ready = true;

        };
    }]
});
