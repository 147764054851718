const ngModule = angular.module('ppa.widgets.debt-report', [
    'ppa.services.rx',
    'ppa.services.debt',
    'ppa.services.observe',
    'ppa.services.client',
    'ppa.services.dialog',
    'ppa.services.snack',
    'ppa.services.utils'
]);

ngModule.component('ppaDebtReport', {
    template: require('./debt-report.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$q', 'rx', 'debtService', 'observeService', 'clientService', 'dialogService', 'snackService', 'utils', function($rootScope, $scope, $q, rx, debtService, observeService, clientService, dialogService, snackService, utils) {

        var vm = this;

        // -- initial state

        // -- util functions

        function isReady() {
            return utils.isReady(
                vm.clients,
                vm.report,
                vm.units,
                vm.buildingsMap
            );
        }

        function init(fetch) {

            debtService.observeDebtReport().subscribe(function(debtsAndExpenses) {
                vm.report = debtService.mergeRepaymentsIntoDebts(debtsAndExpenses[0], debtsAndExpenses[1]);
                vm.ready = isReady();
            });

            observeService.properties().subscribe(function(res){
                vm.units = res.units || vm.units;
                vm.unitsMap = res.unitsMap || vm.unitsMap;
                vm.buildingsMap = res.buildingsMap || vm.buildingsMap;
                vm.ready = isReady();
            });

            clientService.observeClients().subscribe(function(clients) {
                vm.clients = clients;
                vm.ready = isReady();
            });

        }

        function closeEditor(){
            vm.activeId = null;
            dialogService.close();
        }

        function openEditor(debt, units, buildings, clients, onSubmit){

            vm.activeId = debt ? debt._id : 'addDebt';
            var simpleDebt = angular.copy(debt);

            if(debt.property && angular.isObject(debt.property)) {
                simpleDebt.property = debt.property._id;
            }

            var scope = $rootScope.$new();

            scope = Object.assign(scope, {
                debt: simpleDebt,
                units: units,
                buildings: buildings,
                clients: clients,
                onSubmit: onSubmit
            });

            var directive = '<ppa-debt-form debt="debt" units="units" buildings="buildings" clients="clients" on-submit="onSubmit"></ppa-debt-form>';
            var options = {
                contents: directive,
                scope: scope
            };

            return dialogService.show(options).then(function(){
                vm.activeId = null;
            });
        }

        function handleSuccess (verb) {
            snackService.success('Debt ' + verb);
            vm.expense = {};
        }

        function handleError (verb) {
            snackService.error('An error occurred, could not ' + verb + ' the debt');
        }

        function updateDebt(debt) {
            var showError = handleError.bind(null, 'update');
            var showSuccess = handleSuccess.bind(null, 'updated');
            return debtService.updateDebt(angular.copy(debt))
            .then(showSuccess)
            .catch(showError);
        }

        function addDebt(debt) {
            var showError = handleError.bind(null, 'add');
            var showSuccess = handleSuccess.bind(null, 'added');
            return debtService.addDebt(angular.copy(debt))
                .then(closeEditor)
                .then(showSuccess)
                .catch(showError);
        }

        // -- api

        vm.editDebt = function(debt) {
            if(debt._id === vm.activeId) {
                closeEditor();
            } else {
                openEditor(debt, vm.units, vm.buildingsMap, vm.clients, updateDebt);
            }

        };

        // -- scope bindings

        // -- main

        init();

    }]
});
