const ngModule = angular.module('ppa.widgets.date-range', [
    'ppa.services.dialog'
]);

ngModule.component('ppaDateRange', {
    template: require('./date-range.pug'),
    bindings: {
        fromDate: '<',
        toDate: '<'
    },
    controllerAs: 'vm',
    controller: ['$rootScope', '$element', 'dialogService', function($rootScope, $element, dialogService){

        var vm = this;

        // -- util functions

        // -- api

        vm.showPicker = function () {
            var scope = $rootScope.$new();
            scope.fromDate = vm.fromDate;
            scope.toDate = vm.toDate;


            var options = {
                clickClose: true,
                escapeClose: true,
                showClose: true,
                classNames: 'ppa-expense-dialog',
                scope: scope,
                openFrom: $element
            };

            options.contents = '<ppa-date-range-picker from-date="fromDate" to-date="toDate"></ppa-date-range-picker>';

            return dialogService.show(options, true);

        };

        // -- main



    }]
});