import {propIs} from 'ramda';

export interface IncomingPayment {
    datePaid: string;
    amount: number;
    month?: number; // timestamp
}

export interface Expense {
    date: Date;
    amount: number;
    month?: number; // timestamp
}

export type Transaction = IncomingPayment | Expense;

export function isIncomingPayment(item: Transaction): item is IncomingPayment {
    return propIs(String, 'datePaid',  item);
}

export function isExpense(item: Transaction): item is Expense {
    return propIs(String, 'date',  item);
}

export interface DateGraphPoint {
    x: number;
    y: number;
}

