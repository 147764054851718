const ngModule = angular.module('ppa.widgets.expense-form', [
    'ppa.services.client',
    'ppa.services.snack',
    'ppa.widgets.client-form',
    'ppa.widgets.expense-category-selector',
    'ppa.widgets.property-selector'
]);

ngModule.component('ppaExpenseForm', {
    template: require('./expense-form.pug'),
    bindings: {
        expense: '<',
        expenseCategories: '<expenseCategoriesMap',
        debts: '<',
        clients: '<',
        buildings: '<',
        units: '<',
        step: '=?',
        onSubmit: '&'
    },
    controllerAs: 'vm',
    controller: ['clientService', 'snackService', 'ppMoment', function(clientService, snackService, ppMoment) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.addClientStep = function() {
            vm.step = 'client';
        };

        vm.expenseStep = function() {
            vm.step = 'expense';
        };

        vm.onSubmitClient = function(client) {
            return clientService.addClient(client).then(function(){
                vm.step = 'expense';
            });
        };

        vm.submit = function() {
            if (vm.form.$valid) {
                var expense = angular.copy(vm.expense);
                expense.date = ppMoment(expense.date).format("YYYY-MM-DD");
                vm.processing = true;
                vm.onSubmit({
                    expense: expense
                })
                .finally(function(){
                    vm.processing = false;
                    vm.form.$setPristine();
                });
            } else {
                snackService.error('Invalid form, unable to save the expense');
            }
        };


        // -- scope bindings

        vm.$onInit = function() {
            vm.step = 'expense';
        };

        // -- main


    }]
});
