const ngModule = angular.module('ppa.filters.tenancy', []);

ngModule.filter('capitalise', function() {
    return function(input) {
      return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    };
});


ngModule.filter('filterTenancyBySegment', function(){
    return function(tenancies, segment, sortAttribute){
        if(!tenancies) {
            return [];
        }
        var filteredTenancies = tenancies.filter(function(tenancy){
            return tenancy.segments.indexOf(segment) > -1;
        });

        if(sortAttribute) {
            return filteredTenancies.sort(function(a, b) {
                return new Date(a[sortAttribute]).valueOf() - new Date(b[sortAttribute]).valueOf();
            });
        } else {
            return filteredTenancies;
        }
    };
});