const ngModule = angular.module('ppa.services.account', [
    'ppa.services.storage'
]);

ngModule.service('accountService', ['storageService', function(storageService) {
    var api = {};

    var ALLOWED_ACCOUNT_TYPES = [
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
        'senary',
        'septenary',
        'octonary'
    ];

    var ACCOUNT_STORAGE_KEY = 'account';
    var ACCOUNT_PATH = 'https://pinhornproperties.com/account';
    var USER_STORAGE_KEY = 'user';

    var DEFAULT_ACCOUNT_TYPE = 'primary';

    api.setAccount = function(type) {
        if(type && ALLOWED_ACCOUNT_TYPES.indexOf(type) > -1) {
            storageService.set(ACCOUNT_STORAGE_KEY, type);
        }
    };

    api.getAccount = function() {
        return storageService.get(ACCOUNT_STORAGE_KEY) || DEFAULT_ACCOUNT_TYPE;
    };

    api.getAccounts = function() {
        var user = storageService.getJson(USER_STORAGE_KEY) || {};
        return user[ACCOUNT_PATH];
    };


    return api;

}]);