const ngModule = angular.module('ppa.widgets.client-add', [
    'ppa.services.client',
    'ppa.services.snack'
]);

ngModule.component('ppaClientAdd', {
    template: require('./client-add.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['clientService', 'snackService', function(clientService, snackService) {

        var vm = this;

        vm.client = {};

        // -- util functions

        function onSuccess() {
            snackService.success('Client saved');
            vm.client = {};
        }

        function onError() {
            snackService.error('Unable to save client');
        }

        // -- api

        vm.submitClient = function(client) {
            return clientService.addClient(client).then(onSuccess, onError);
        };

    }]
});
