const ngModule = angular.module('ppa.widgets.edit-tenancy', []);

ngModule.component('ppaEditTenancy', {
    template: require('./edit-tenancy.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['tenancyService', 'utils', 'snackService', function(tenancyService, utils, snackService) {

        var vm = this;

        // -- initial state

        var fetchTenancies = tenancyService.getTenancies.bind(this, true);

        // -- util functions

        function sanitizeTenancyPayload(tenancy){
            var payload = angular.copy(tenancy);

            if(angular.isObject(payload.property)) {
                payload.property = payload.property._id;
            }

            if(payload.tenants && payload.tenants.isArray(tenancy.tenants)) {
                payload.tenants = payload.tenants.map(utils.extractId);
            }

            return payload;
        }

        function handleSuccess() {
            snackService.success('Tenancy updated');
        }

        function handleError() {
            snackService.error('Tenancy could not be updated');
        }


        vm.updateTenancy = function(tenancy){

            var payload = sanitizeTenancyPayload(tenancy);
            return tenancyService.updateTenancy(payload)
                .then(fetchTenancies)
                .then(handleSuccess, handleError);

        };

        // -- Main
    }
]});