const ngModule = angular.module('ppa.widgets.rent-report', [
    'ppa.services.rx',
    'ppa.services.observe',
    'ppa.services.rent',
    'ppa.services.preference',
    'ppa.services.dialog',
    'ppa.services.utils',
    'ppa.services.date',
    'ppa.filters.date',
    'ppa.widgets.date-range'
]);

ngModule.component('ppaRentReport', {
    template: require('./rent-report.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$http', '$timeout', 'rx', 'observeService', 'rentService', 'propertyService', 'preferenceService', 'dialogService', 'utils', 'dateService',
    function($rootScope, $scope, $http, $timeout, rx, observeService, rentService, propertyService, preferenceService, dialogService, utils, dateService) {

        var vm = this;

        // -- utils functions

        function isReady() {
            return utils.isReady(
                vm.rents,
                vm.buildings,
                vm.units,
                vm.tenancies,
                vm.tenants
            );
        }

        function unsubscribeReport() {
            utils.unsubscribe(vm.reportStreamSubscription);
        }

        function unsubscribeReportData() {
            utils.unsubscribe(vm.reportDataStream);
        }

        function unSubscibeDownload() {
            utils.unsubscribe(vm.downloadStreamSubscription);
        }

        function setupReport(property) {

            vm.reportStream = rentService.observeReport(vm.dateRange.fromDate, vm.dateRange.toDate, property);

            vm.reportStreamSubscription = vm.reportStream.subscribe(function(res) {

                    var report = rentService.constructRentReport(res[0], res[1]);
                    vm.rents = report.rents;
                    vm.total = report.total;

                    if(vm.activeId && !report.paymentsMap[vm.activeId]) {
                        dialogService.close();
                    }

                    vm.ready = isReady();
            }, function() {
                vm.reportError = true;
            });
        }

        function setupReportData() {
           vm.reportDataStream = rx.Observable.merge(
                observeService.properties(),
                observeService.tenants(),
                observeService.tenancies()
            ).subscribe(function(res){
                vm.buildings = res.buildingsMap || vm.buildings;
                vm.units = res.unitsMap || vm.units;
                vm.unitsList = res.units || vm.unitsList;
                vm.tenants = res.tenantsMap || vm.tenants;
                vm.tenancies = res.tenanciesMap || vm.tenancies;
                vm.ready = isReady();
            });
        }

        function deletePayment(paymentId, rent) {
            return rentService.deletePayment(paymentId, rent);
          }

        function openEditor(rent){
            var scope = $rootScope.$new();

            var propertyName = propertyService.contructPropertyName(rent.property, vm.units, vm.buildings);

            scope.payments = rent.payments;
            scope.rent = rent;
            scope.propertyName = propertyName;
            scope.onDelete = deletePayment;
            scope.rentStream = vm.reportStream;

            var contents = '<div ppa-payment-history-list="payments" rent="rent" rent-stream="rentStream" property-name="propertyName" on-delete="onDelete(id, rent)"></div>';

            var options = {
                contents: contents,
                scope: scope
            };

            vm.activeId = rent._id;

            return dialogService.show(options).finally(function(){
                vm.activeId = null;
            });
        }

        // -- api

        vm.sumTotal = utils.sumAmounts.bind(null, 'amount');

        vm.setupReport = function() {
            setupReport(vm.filteredProperty);
        };

        vm.clickRow = function(rent) {
            return openEditor(rent);
        };

        vm.filterProperties = function(property) {
            setupReport(property);
        };

        vm.getTenantList = function(tenancy) {
            var tenants = vm.tenancies[tenancy].tenants;
            if(!angular.isArray(tenants)) {
                return;
            }
            return tenants.reduce(function(list, id){
                var tenant = vm.tenants[id];

                if(!tenant && angular.isObject(tenant)) {
                    return list;
                }

                if(list) {
                    list += ', ';
                }

                list += tenant.firstName + ' ' + tenant.lastName;
                return list;
            }, '');
        };

        vm.downloadRentReport = function() {

            vm.downloadError = null;

            vm.downloadingReport = true;

            vm.downloadStreamSubscription = vm.reportStream.pipe(rx.operators.take(1)).subscribe(function(res){
                rentService.getReportDownloadUrl(
                    res[1],
                    vm.dateRange.fromDate,
                    vm.dateRange.toDate,
                    vm.buildings,
                    vm.units,
                    vm.tenancies,
                    utils.createIdMap(res[0]),
                    vm.tenants
                ).then(function(res){
                    vm.fileDownloadLink = res.url;

                    return $timeout(function(){
                        document.getElementById('rent-report-file-download').click();
                    }, 1000);

                }, function(err){
                    vm.downloadError = true;
                }).then(function(){
                    vm.downloadingReport = false;
                });
            });


        };

        // -- scope bindings

        $scope.$on("dateChanged", function(event, range) {
            vm.dateRange = range;
            vm.ready = false;
            setupReport(vm.filteredProperty);
        });

        $scope.$on("refreshReport", function() {
            vm.ready = false;
            setupReport(vm.filteredProperty);
        });

        vm.$onDestroy = function() {
            unsubscribeReport();
            unsubscribeReportData();
            unSubscibeDownload();
        };

        // -- main

        setupReportData();

        dateService.getDateRange().then(function(dateRange){
            vm.dateRange = dateRange;
            setupReport();
        });

      }]
});
