const ngModule = angular.module('ppa.widgets.tenancy-add', [
    'ppa.services.rx',
    'ppa.services.tenancy',
    'ppa.services.utils',
    'ppa.services.snack',
    'ppa.widgets.tenancy-form'
]);

ngModule.component('ppaTenancyAdd', {
        template: require('./tenancy-add.pug'),
        bindings: {},
        controllerAs: 'vm',
        controller: ['rx', 'observeService', 'tenancyService', 'utils', 'snackService', function(rx, observeService, tenancyService, utils, snackService) {
            var vm = this;

            vm.tenancy = {};
            vm.alive = true;

            // -- util functions

            function isAlive() {
                return vm.alive;
            }

            function isReady() {
                return utils.isReady(
                    vm.units,
                    vm.tenants,
                    vm.buildings,
                    vm.tenancies
                );
            }

            function init() {
                rx.Observable.merge(
                    observeService.properties(),
                    observeService.tenants(),
                    observeService.tenancies()
                )
                .takeWhile(isAlive)
                .subscribe(function(res){
                    vm.units = res.units || vm.units;
                    vm.tenants = res.tenants || vm.tenants;
                    vm.buildings = res.buildingsMap || vm.buildings;
                    vm.tenancies = res.tenancies || vm.tenancies;
                    vm.ready = isReady();
                });
            }

            function handleSuccess () {
                vm.tenancy = {};
                snackService.success('Tenancy saved');
            }

            function handleError () {
                snackService.error('An error occurred, could not add the tenancy');
            }

            // -- api

            vm.submitTenancy = function(tenancy) {
                return tenancyService.addTenancy(tenancy)
                    .then(handleSuccess, handleError);
            };

            // -- scope bindings

            // -- lifecycle

            vm.$onDestroy = function(){
                vm.alive = false;
            };

            // -- main

            init();



        }]
    }
);
