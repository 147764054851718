const ngModule = angular.module('ppa.widgets.edit-expense-form', []);

ngModule.directive('ppaEditExpenseForm', [function() {
    return {
        restrict: 'A',
        template: require('./edit-expense-form.pug'),
        scope:{
            expense: '<',
            expenseCategories: '<expenseCategoriesMap',
            clients: '<',
            debts: '<',
            buildings: '<',
            units: '<',
            onDelete: '&',
            onUpdate: '&'
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['$scope', 'snackService', function($scope, snackService) {
            var vm = this;


            // -- api

            vm.deleteExpense = function() {
                var r = confirm("Are you sure you want to delete this expense?");
                if (r === true) {
                    vm.processing = true;
                    return vm.onDelete({
                        expenseId: vm.expense._id
                    })
                    .then(function(){
                        snackService.success('Expense deleted');
                    }, function(){
                        snackService.error('Expense could not be deleted');
                    })
                    .finally(function(){
                        vm.processing = false;
                    });

                }
            };

            vm.updateExpense = function(expense) {
                return vm.onUpdate({
                    expense: expense
                });
            };

            // -- scope bindings

            // -- Main


        }]
    };
}]);
