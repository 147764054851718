
export class PropertyService {


  constructor(
    private $cacheFactory: any,
    private $q: any,
    private rx: any,
    private resources: any,
    private utils: any
  ) {

  }

  static $inject = ['$cacheFactory', '$q', 'rx', 'resources', 'utils'];

    BUILDING_ENDPOINT = '/api/buildings';
    UNIT_ENDPOINT = '/api/units';

    promiseCache = this.$cacheFactory('property');

    unitSubject =  new this.rx.Subject();
    unitIdMapSubject =  new this.rx.Subject();
    buildingSubject =  new this.rx.Subject();
    buildingIdMapSubject =  new this.rx.Subject();

    reduce = this.rx.Observable.reduce;

    unitIdMapStream = this.unitIdMapSubject.asObservable().pipe(this.rx.operators.distinctUntilChanged());

    buildingIdMapStream = this.buildingIdMapSubject.asObservable().pipe(this.rx.operators.distinctUntilChanged());

    getUnits(fetch: boolean = false): Promise<any> {
        const cacheKey = 'units';
        if (fetch || !this.promiseCache.get(cacheKey)) {
            const promise = this.resources.httpGet(this.UNIT_ENDPOINT)
                .catch(() => {
                    this.promiseCache.remove(cacheKey);
                    return this.$q.reject({
                        reason: 'error.get-units.unknown'
                    });
                });
            this.promiseCache.put(cacheKey, promise);
        }

        return this.promiseCache.get(cacheKey);
    }

    getBuildings(fetch: boolean = false): Promise<any> {
        const cacheKey = 'buildings';
        if (fetch || !this.promiseCache.get(cacheKey)) {
             const promise = this.resources.httpGet(this.BUILDING_ENDPOINT)
                .catch(() => {
                    this.promiseCache.remove(cacheKey);
                    return this.$q.reject({
                        reason: 'error.get-buildings.unknown'
                    });
                });
             this.promiseCache.put(cacheKey, promise);
        }

        return this.promiseCache.get(cacheKey);
    }

    getPropertyName(buildings, units, propertyId): string {
        const unit = units[propertyId] || {};
        const building = buildings[unit.building];

        const buildingName = building ? building.name + ' | ' : '';
        const unitName = unit.name ? unit.name : '';
        return buildingName + unitName;
    }

    getBuildingOrPropertyName(buildings, units, propertyId, buildingId): string {

        if (propertyId) {
            return this.getPropertyName(buildings, units, propertyId);
        }

        if (buildingId) {
            return buildings[buildingId].name;
        }

        return '';
    }

    getUnitsIdMap(fetch: boolean = false) {
        return this.getUnits().then((units) => {
            return this.utils.createIdMap(units);
        });
    }

    refreshUnits() {
        return this.getUnits(true).then((units) => {
            this.unitSubject.next(units);
            this.unitIdMapSubject.next(units.reduce(this.utils.idMap, {}));
        });
    }

    getBuildingsIdMap(fetch: boolean = false): Promise<any> {
        const cacheKey = 'building-id-map';

        if (fetch || !this.promiseCache.get(cacheKey)) {
            const promise = this.getBuildings(false).then((buildings) => {
                return buildings.reduce(this.utils.idMap, {});
            });

            this.promiseCache.put(cacheKey, promise);
        }

        return this.promiseCache.get(cacheKey);
    }

    refreshBuildings() {
        return this.getBuildings(true).then((buildings) => {
            this.buildingSubject.next(buildings);
            this.buildingIdMapSubject.next(buildings.reduce(this.utils.idMap, {}));
        });
    }

    contructPropertyName(unitId, units, buildings): string {
        return buildings[units[unitId].building].name + ' | ' + units[unitId].name;
    }

   observeUnits() {
        const promise = this.rx.Observable.fromPromise(this.getUnits());
        const stream = this.unitSubject.asObservable().pipe(this.rx.operators.distinctUntilChanged());
        return this.rx.Observable.merge(promise, stream);
    }

    observeUnitsIdMap() {
        const promise = this.rx.Observable.fromPromise(this.getUnitsIdMap());
        const stream = this.unitIdMapSubject.asObservable().pipe(this.rx.operators.distinctUntilChanged());
        return this.rx.Observable.merge(promise, stream);
    }


    observeBuildings() {
        const promise = this.rx.Observable.fromPromise(this.getBuildings());
        const stream = this.buildingSubject.asObservable().pipe(this.rx.operators.distinctUntilChanged());
        return this.rx.Observable.merge(promise, stream);
    }

    observeBuildingsIdMap() {
        const promise = this.rx.Observable.fromPromise(this.getBuildingsIdMap());
        const stream = this.buildingIdMapSubject.asObservable().pipe(this.rx.operators.distinctUntilChanged());
        return this.rx.Observable.merge(promise, stream);
    }

    purgeCache() {
        this.promiseCache.removeAll();
    }


}


