const ngModule = angular.module('ppa.widgets.menu', [
    'ppa.services.account',
    'ppa.services.menu',
    'ppa.services.cache',
    'ppa.services.user'
]);

ngModule.component('ppaMenu', {
    template: require('./menu.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', '$state', '$transitions', 'authService', 'userService', 'accountService', 'menuService', 'cacheService', function($rootScope, $state, $transitions, authService, userService, accountService, menuService, cacheService){

        var vm = this;

        // -- initial state

        // -- util functions

        function getNumberOfAccounts(accounts) {
            if(!accounts || typeof accounts !== 'object') {
                return 0;
            }
            return Object.keys(accounts).length;

        }

        // -- api

        vm.closeMenu = menuService.closeMenu;

        vm.logout = function(){
            authService.logout();
            $state.go($state.current, {}, {reload: true});
        };

        vm.isCurrentMenuItem = function(menuItem){
            return $state.current.name.indexOf(menuItem) > -1;
        };

        vm.updateAccount = function(){
            cacheService.purgeAll();
            accountService.setAccount(vm.account);
            $state.go($state.current, {}, {reload: true});
        };

        // -- scope bindings

        // -- main

        vm.account = accountService.getAccount();

        vm.user = userService.getUser();

        vm.hasMultipleAccounts = getNumberOfAccounts(vm.user.account) > 1;


    }]
});