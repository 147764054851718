
var TOAST_CLASS = 'ppa-toast';


const ngModule = angular.module('ppa.services.snack', [
    'ngMaterial'
]);

ngModule.service('snackService', ['$rootScope', '$mdToast', function ($rootScope, $mdToast) {
    var api = {};

    api.success = function (message) {
        var toast = $mdToast.simple().textContent(message).toastClass(TOAST_CLASS);
        $mdToast.show(toast);
    };


    api.error = function(message) {
        var toast = $mdToast.simple().textContent(message).theme('warn').toastClass(TOAST_CLASS);
        $mdToast.show(toast);
    };

    return api;
}]);
