const ngModule = angular.module('ppa.widgets.debt-add', [
    'ppa.services.debt',
    'ppa.services.snack',
    'ppa.services.observe',
    'ppa.services.client',
    'ppa.services.utils',
    'ppa.widgets.debt-form'
]);

ngModule.component('ppaDebtAdd', {
    template: require('./debt-add.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'debtService', 'snackService', 'observeService', 'clientService', 'utils', function($scope, debtService, snackService, observeService, clientService, utils) {

        var vm = this;

        vm.debt = {};

        // -- util functions

        function isReady() {
            return utils.isReady(
                vm.buildingsMap,
                vm.units,
                vm.clients
            );
        }

        function init(){

            observeService.properties().subscribe(function(res){
                vm.units = res.units || vm.units;
                vm.buildingsMap = res.buildingsMap || vm.buildingsMap;
                vm.ready = isReady();
            });

            clientService.observeClients().subscribe(function(clients) {
                vm.clients = clients;
                vm.ready = isReady();
            });
        }


        function showSuccess() {
            snackService.error('Debt saved');
            vm.debt = {};

        }

        function showError() {
            snackService.error('Unable to save debt');
        }

        function addDebt(debt) {
            return debtService.addDebt(debt)
                .then(showSuccess, showError);
        }

        // -- api

        vm.submitDebt = addDebt;

        // -- main

        init();
    }]
});


