const ngModule = angular.module('ppa.widgets.close-tenancy', [
    'ppa.services.dialog'
]);

ngModule.component('ppaCloseTenancy', {
    template: require('./close-tenancy.pug'),
    bindings: {
        tenancy: '<',
        onSubmit: '&',
        tenancyAttributes: '<'
    },
    controllerAs: 'vm',
    controller: ['dialogService', function(dialogService){
        var vm = this;
        vm.processing = false;

        vm.newRents = [];

        vm.closeTenancy = function() {
            vm.feedbackEnabled = true;
            if (vm.form.$valid){
                vm.processing = true;
                vm.onSubmit({
                    tenancy: vm.tenancy,
                    newRents: vm.newRents
                }).finally(function(){
                    vm.processing = false;
                });
            }
        };

        vm.addNewRent = function() {
            vm.newRents.push({
                property: vm.tenancy.property,
                tenancy: vm.tenancy._id
            });
        };

        vm.removeNewRent = function(indexToRemove) {
            vm.newRents = vm.newRents.filter(function(item, index) {
                return indexToRemove !== index;
            });
        };

        vm.cancel = function() {
            dialogService.close();
        };
    }]
});