
const ngModule = angular.module('ppa.services.debt', [
    'ppa.services.rx',
    'ppa.services.resources',
    'ppa.services.expense',
    'ppa.services.utils'
]);

ngModule.service('debtService', ['$q', '$cacheFactory', 'rx', 'resources', 'expenseService', 'propertyService', 'utils', function($q, $cacheFactory, rx, resources, expenseService, propertyService, utils) {

    var api = {};

    var DEBT_ENDPOINT = '/api/debts';

    var promiseCache = $cacheFactory('debt');

    var subject = new rx.Subject();
    var reportSubject = new rx.Subject();

    var debtExpenseSubject = new rx.Subject();

    function extractPropertyIdFromDebt(debt) {
        if(debt.property && angular.isObject(debt.property)){
            debt.property = debt.property._id;
        }
        return debt;
    }

    function getDebts(fetch) {
        var cacheKey = 'debts';
        if(fetch || !promiseCache.get(cacheKey)) {
            var promise = resources.httpGet(DEBT_ENDPOINT, null, 'debts', fetch)
                .then(function(debts){
                    return debts.map(extractPropertyIdFromDebt);
                })
                .catch(function(err){
                    promiseCache.remove(cacheKey);
                    return $q.reject(err);
                });
            promiseCache.put(cacheKey, promise);
        }

        return promiseCache.get(cacheKey);
    }


    api.getDebts = getDebts;

    function refreshDebts() {
        return getDebts(true).then(function(debts){
            subject.next(debts);
        });
    }

    api.contructSlimDebtProperty = function(unitId) {
        var promises = {
            units: propertyService.getUnitsIdMap(),
            buildings: propertyService.getBuildingsIdMap()
        };

        return $q.all(promises).then(function(res){
            return {
                _id: unitId,
                propertyName: res.units[unitId].name,
                buildingName: res.buildings[res.units[unitId].building].name
            };
        });
    };

    api.observeDebts = function() {
        var promise = rx.Observable.fromPromise(getDebts());
        var stream = subject.asObservable();
        return rx.Observable.merge(promise, stream);
    };


    api.observeDebtReport = function() {
        return rx.Observable.combineLatest(
            api.observeDebts(),
            expenseService.observeDebtExpenses()
        );
    };

    api.mergeRepaymentsIntoDebts = function(debts, repayments) {
        var debtsWithTotal = debts.map(function(debt){
            debt.amountRepaid = 0;
            if(repayments[debt._id]) {
                debt.amountRepaid = repayments[debt._id].reduce(utils.sumAmounts.bind(null, 'amount'), 0);
            }
            return debt;
        });

        var res = {
            debts: debtsWithTotal,
            totalDebt: debtsWithTotal.reduce(utils.sumAmounts.bind(null, 'capital'), 0),
            totalDebtsRepaid:  debtsWithTotal.reduce(utils.sumAmounts.bind(null, 'amountRepaid'), 0)
        };

        res.totalDebtRemaining = res.totalDebt - res.totalDebtsRepaid;
        return res;
    };


    api.updateDebt = function(debt) {
        var payload = angular.copy(debt);
        var promise = debt.property ? api.contructSlimDebtProperty(payload.property) : $q.when(null);

        return promise.then(function(property){
            var endpoint = DEBT_ENDPOINT + '/'+ debt._id;


            if(property) {
                payload.property = property;
            }

            return resources.httpPut(endpoint, payload);
        }).then(function(){
            return refreshDebts();
        });

    };

    api.addDebt = function(debt) {
        var payload = angular.copy(debt);
        var promise = payload.property ? api.contructSlimDebtProperty(payload.property) : $q.when(null);

        return promise.then(function(property){
            var endpoint = DEBT_ENDPOINT;


            if(property) {
                payload.property = property;
            }

            return resources.httpPost(endpoint, payload);
        }).then(function(){
            return refreshDebts();
        });
    };

    api.purgeCache = function() {
        promiseCache.removeAll();
    };


    return api;

}]);