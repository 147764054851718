const ngModule = angular.module('ppa.services.resources', [
    'ppa.values.base-api',
    'ppa.services.account'
]);

ngModule.service('resources', ['$http', '$rootScope', '$q', '$cacheFactory', 'baseApi', 'accountService', function($http, $rootScope, $q, $cacheFactory, baseApi, accountService) {
    var api = {};
    var promiseCache =  $cacheFactory('resources');

    var QUERY_COMPARATOR_KEY_MAP = {
        '<=': '$lte__',
        '>=': '$gte__',
        '>': '$gt__',
        '<': '$lt__',
        '!=': '$ne__'
    };

    var QUERY_COMPARATOR_TYPE_MAP = {
        boolean: 'boolean__',
        date: 'date__',
        number: 'number__'
    };

    function queryConstructor(queryString, query) {
        if(!angular.isDefined(query.where) || !angular.isDefined(query.value)) {
            return queryString;
        }
        var where = query.where;
        var value = query.value;

        var comparator = QUERY_COMPARATOR_KEY_MAP[query.comparator];
        var type = QUERY_COMPARATOR_TYPE_MAP[query.type];

        if(comparator && type) {
            where = comparator + type + where;
        }

        return queryString + where + '=' + value + '&';
    }

    function constructEndpoint(url, queryString) {
        return baseApi + url + (queryString || '');
    }


    function headers() {
        return {
            account: accountService.getAccount()
        };
    }

    function options() {
        return {
            headers: headers()
        };
    }


    api.queryStringConstructor = function(queries) {
        if(angular.isArray(queries) && queries.length) {
            return queries.reduce(queryConstructor, '?').slice(0, -1);
        }
        return '';
    };


    api.httpGet = function(url, queries, cacheKey, fetch) {
        var queryString = api.queryStringConstructor(queries);

        var promsieCacheKey = cacheKey || url.replace(/\//g, '.') + queryString;

        var endpoint = constructEndpoint(url, queryString);

        if (!angular.isDefined(promiseCache.get(promsieCacheKey)) || fetch) {
            if(angular.isDefined(promiseCache.get(promsieCacheKey))){
                promiseCache.remove(promsieCacheKey);
            }
            var promise = $http.get(endpoint, options()).then(function (result) {
                    return result.data;
                },
                function (error) {
                    var data = error.data || {};
                    promiseCache.remove(promsieCacheKey);
                    switch (error.status) {
                        case 401:
                            return $q.reject({
                                reason: 'app.auth.failed',
                            });
                        default:
                            return $q.reject({
                                status: error.status,
                                reason: data.message,
                                data: null
                            });
                    }
                });
            promiseCache.put(promsieCacheKey, promise);
            return promise;
        } else {
            return promiseCache.get(promsieCacheKey);
        }
    };

    api.httpPost = function(url, payload) {

        var endpoint = constructEndpoint(url);

        return $http.post(endpoint, payload, options()).then(function(item) {
                return item.data;
            },
            function(error) {
                var data = error.data || {};
                switch (error.status) {
                    case 401:
                        $rootScope.$broadcast('app.auth.failed');
                        return $q.reject({
                            reason: 'app.auth.failed',
                        });
                    case 400:
                    case 500:
                        return $q.reject({
                            reason: data.message,
                        });
                }
            });
    };


    api.httpDelete = function(url) {

        var endpoint = constructEndpoint(url);

        return $http.delete(endpoint, options()).then(function() {
                return true;
            },
            function(error) {
                var data = error.data || {};
                switch (error.status) {
                    case 401:
                        $rootScope.$broadcast('app.auth.failed');
                        return $q.reject({
                            reason: 'app.auth.failed',
                        });
                    case 400:
                    case 500:
                        return $q.reject({
                            reason: data.message
                        });
                }
            });
    };

    api.httpPut = function(url, payload) {

        var endpoint = constructEndpoint(url);

        return $http.put(endpoint, payload, options()).then(function() {
                return true;
            },
            function(error) {
                var data = error.data || {};
                switch (error.status) {
                    case 401:
                        $rootScope.$broadcast('app.auth.failed');
                        return $q.reject({
                            reason: 'app.auth.failed',
                        });
                    case 400:
                    case 500:
                        return $q.reject({
                            reason: data.message
                        });
                }
            });
    };

    api.purgeCache = function(cacheKey) {
        promiseCache.remove(cacheKey);
    };

    api.purgeAllCache = function() {
        promiseCache.removeAll();
    };

    return api;
}]);
