import {Component, Inject, Input, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth';
import {LEGACY_AUTH} from '../../services/ajs-upgraded-providers';

@Component({
  selector: 'ppa-login',
  template: require('./login.html'),
  styles: [``]
})
export class LoginComponent implements OnInit {

  @Input() loginError: boolean;

  constructor(@Inject(LEGACY_AUTH) private authService: AuthService) {

  }

  ngOnInit(): void {

  }

  login() {
    this.authService.login();
  }


}



