const ngModule = angular.module('ppa.services.user', []);

ngModule.service('userService', ['storageService', function(storageService) {
    var api = {};

    var USER_STORAGE_KEY = 'user';
    var NAMESPACE = 'https://pinhornproperties.com/';

    function normaliseUser(user) {
        var normalisedUser = {};
        for(var key in user) {
            if(key.indexOf(NAMESPACE) > -1) {
                normalisedUser[key.replace(NAMESPACE, '')] = user[key];
            }
        }

        return normalisedUser;
    }

    api.getUser = function() {
        return normaliseUser(storageService.getJson(USER_STORAGE_KEY)) || {};
    };


    return api;

}]);