import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material';
import {LoginComponent} from './login';


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
      CommonModule,
      BrowserModule,
      MatButtonModule
  ],
  exports: [
    LoginComponent,
    MatButtonModule
  ]
})
export class LoginModule {
}
