const ngModule = angular.module('ppa.widgets.fab', [
    'ppa.services.dialog',
    'ppa.services.tenant',
    'ppa.services.debt',
    'ppa.services.snack',
    'ppa.services.observe',
    'ppa.services.client',
    'ppa.services.utils'
]);

ngModule.component('ppaFab', {
    template: require('./fab.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', 'dialogService', 'tenantService', 'debtService', 'snackService', 'observeService', 'clientService', 'utils', function($rootScope, dialogService, tenantService, debtService, snackService, observeService, clientService, utils) {

        var vm = this;

        // -- initial state

        var rect = {
            top: 900,
            left: 2000,
            width: 40,
            height: 40
        };

        var __options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            openFrom: rect,
            closeTo: rect
        };

        // -- util functions

        function showSuccess(item) {
            snackService.success(item + ' saved');
        }

        function showError(item) {
            snackService.error('Unable to save ' + item);
        }


        // -- api

        vm.addExpense = function() {
            var scope = $rootScope.$new();

            var options = utils.extend(__options, {
                classNames: 'ppa-expense-dialog',
                scope: scope
            });

            options.contents = '<ppa-expense-add></ppa-expense-add>';

            return dialogService.show(options);
        };

        vm.addTenant = function() {
            var scope = $rootScope.$new();

            var options = utils.extend(__options, {
                classNames: 'ppa-tenant-add',
                scope: scope
            });

            options.contents = '<ppa-tenant-add></ppa-tenant-add>';

            return dialogService.show(options);
        };

        vm.addClient = function() {
            var scope = $rootScope.$new();
            var options = utils.extend(__options, {
                classNames: 'ppa-client-add',
                scope: scope
            });

            options.contents = '<ppa-client-add></ppa-client-add>';

            return dialogService.show(options);
        };

        vm.addTenancy = function() {
            var scope = $rootScope.$new();
            var options = utils.extend(__options, {
                classNames: 'ppa-tenancy-add',
                scope: scope
            });

            options.contents = '<ppa-tenancy-add></ppa-tenancy-add>';

            return dialogService.show(options);
        };

       vm.addDebt = function() {
            var scope = $rootScope.$new();
            var options = utils.extend(__options, {
                classNames: 'ppa-debt-add',
                scope: scope
            });

            options.contents = '<ppa-debt-add></ppa-debt-add>';

            return dialogService.show(options);
        };


        // -- Main

    }
]});