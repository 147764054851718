var FROM_DATE_PREFERNCE_KEY = 'date-range.from-date';
var TO_DATE_PREFERNCE_KEY = 'date-range.to-date';

const ngModule = angular.module('ppa.widgets.date-range-picker', [
    'ngMaterial',
    'ppa.services.tax',
    'ppa.services.preference',
    'ppa.services.dialog'
]);

ngModule.component('ppaDateRangePicker', {
    template: require('./date-range-picker.pug'),
    bindings: {
        fromDate: '<',
        toDate: '<'
    },
    controllerAs: 'vm',
    controller: ['$rootScope', '$q', 'taxService', 'preferenceService', 'dialogService', 'ppMoment', function($rootScope, $q, taxService, preferenceService, dialogService, ppMoment) {

        var vm = this;


        // -- util functions

        function normaliseDate(date) {
            return ppMoment(date).format();
        }

        function updatePreferences(fromDate, toDate) {

            var promises = [
                preferenceService.updatePreference(normaliseDate(fromDate), FROM_DATE_PREFERNCE_KEY),
                preferenceService.updatePreference(normaliseDate(toDate), TO_DATE_PREFERNCE_KEY)
            ];

            return $q.all(promises);
        }

        // -- api

        vm.cancel = function() {
            dialogService.close();
        };

        vm.changeDate = function() {
            vm.processing = true;
            $rootScope.$broadcast("dateChanged", {fromDate: vm.fromDate, toDate: vm.toDate});
            updatePreferences(vm.fromDate, vm.toDate).finally(function(){
                vm.processing = false;
                dialogService.close();
            });
        };

        // -- main

    }]
});
