import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import {upgradeModule} from '@uirouter/angular-hybrid';
import {UIRouter, UrlService} from '@uirouter/core';
// import {moment} from 'moment';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {downgradeComponent} from '@angular/upgrade/static';

declare global {
    interface Window {
        $: any;
        jQuery: any;
    }
}

window.$ = window.jQuery = require('jquery');

import './polyfills';

import 'angular-material';
require('angular-auth0/src');
require('chosen-js');
require('angular-chosen-localytics');
require('rxjs/index');
require('auth0-js');
const moment = require('moment');
import './stylesheets/style.scss';
import 'angular-jwt';

import 'jquery';

import './components/autoCompleteSelect/auto-complete-select';

import './widgets/clientAdd/client-add';
import './widgets/clientForm/client-form';
import './widgets/closeTenancy/close-tenancy';
import './widgets/dashboard/dashboard';
import './widgets/dateRange/date-range';
import './widgets/dateRangePicker/date-range-picker';
import './widgets/debtAdd/debt-add';
import './widgets/debtForm/debt-form';
import './widgets/debtReport/debt-report';
import './widgets/depositHeld/deposit-held';
import './widgets/editExpenseForm/edit-expense-form';
import './widgets/expenseForm/expense-form';
import './widgets/editRentForm/edit-rent-form';
import './widgets/editTenancy/edit-tenancy';
import './widgets/expenseAdd/expense-add';
import './widgets/expenseCategorySelector/expense-category-selector';
import './widgets/expenseEdit/expense-edit';
import './widgets/expenseReport/expense-report';
import './widgets/fab/fab';
import './widgets/login/login';
import './widgets/main/main';
import './widgets/manageTenancies/manage-tenancies';
import './widgets/manageTenants/manage-tenants';
import './widgets/menu/menu';
import './widgets/paymentHistoryList/payment-history-list';
import './widgets/propertySelector/property-selector';
import './widgets/rentReconciliation/rent-reconciliation';
import './widgets/rentCard/rent-card';
import './widgets/rentReconciliation/rent-reconciliation';
import './widgets/rentReport/rent-report';
import './widgets/segmentSearch/segment-search';
import './widgets/tenancyAdd/tenancy-add';
import './widgets/tenancyForm/tenancy-form';
import './widgets/tenancyCard/tenancy-card';
import './widgets/tenantForm/tenant-form';
import './widgets/tenantAdd/tenant-add';
import './widgets/topBar/top-bar';
import './widgets/spinner/spinner';
import './directives/focusFirstError';
import './directives/moneymask';
import './directives/bgColourContent';
import './controllers/callback';
import './services/account/account';
import {AuthService} from './services/auth/auth';
import {PropertyService} from './services/property/property';
import { DashboardGraphComponent } from './widgets/dashboardGraph/dashboard-graph';
import './services/cache/cache';
import './services/client/client';
import './services/dashboard/dashboard';
import './services/date/date';
import './services/debt/debt';
import './services/dialog/dialog';
import './services/expense/expense';
import './services/masks/masks';
import './services/menu/menu';
import './services/number/number';
import './services/observe/observe';
import './services/ping/ping';
import './services/preference/preference';
import './services/rent/rent';
import './services/resources/resources';
import './services/rx/rx';
import './services/snack/snack';
import './services/storage/storage';
import './services/tax/tax';
import './services/tenancy/tenancy';
import './services/tenant/tenant';
import './services/user/user';
import './services/utils/utils';

import './filters/date';
import './filters/tenancy';
import './filters/url';

import './values/baseApi';

import {LoginComponent} from './widgets/login/login';
import {PropertyAddComponent} from './widgets/propertyAdd/property-add';

const ngModule = angular.module('ppa', [
    uiRouter,
    upgradeModule.name,
    'auth0.auth0',
    'angular-jwt',
    'ngMaterial',
    'ui.router',
    'localytics.directives',
    'ppa.widgets.dashboard',
    'ppa.widgets.expense-report',
    'ppa.widgets.rent-report',
    'ppa.widgets.manage-tenancies',
    'ppa.widgets.debt-report',
    'ppa.widgets.manage-tenants',
    'ppa.controllers.callback',
    'ppa.widgets.spinner',
    'ppa.widgets.main',
    'ppa.widgets.menu',
    'ppa.widgets.top-bar',
    'ppa.widgets.client-add',
    'ppa.widgets.tenancy-add',
    'ppa.widgets.close-tenancy',
    'ppa.widgets.debt-add',
    'ppa.widgets.expense-add',
    'ppa.widgets.tenant-add',
    'ppa.widgets.edit-rent-form',
    'ppa.widgets.payment-history-list',
    'ppa.widgets.expense-edit',
    'ppa.widgets.date-range-picker',
    'ppa.directives.money-mask',
    'ppa.services.mask',
    'ppa.directives.focus-first-error',
    'ppa.services.utils',
    'ppa.services.menu',
    'ppa.services.ping',
    'ppa.values.base-api'
]).run(['$rootScope', '$locale', '$transitions', 'authService',  'baseApi', 'dialogService',
    function($rootScope, $locale, $transitions, authService, baseApi, dialogService) {

    // -- api

    // -- main
    authService.handleAuthentication();

    $locale.NUMBER_FORMATS.CURRENCY_SYM = '£';
    $rootScope.baseApi = baseApi;

    $transitions.onStart({}, function(transition) {
        if (transition.to().name !== 'app.callback') {
            $rootScope.loggedIn = authService.isAuthenticated();
        }
    });

    $transitions.onFinish({}, function() {
        dialogService.close();
    });


}]);

ngModule.service('ppMoment', [function() {
    return moment;
}]);

ngModule.config(['$locationProvider', '$httpProvider', '$stateProvider', '$mdDateLocaleProvider',
'$mdIconProvider', 'angularAuth0Provider', 'jwtOptionsProvider', 'baseApi',
    ($locationProvider, $httpProvider, $stateProvider, $mdDateLocaleProvider, $mdIconProvider,
        angularAuth0Provider, jwtOptionsProvider, baseApi) => {

        angularAuth0Provider.init({
            clientID: '3DeUZWBg4K6ZmatfR0inhH58queFNIR1',
            domain: 'egarof.eu.auth0.com',
            responseType: 'token id_token',
            redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
             audience: 'https://pinhornproperties.com',
            scope: 'openid profile email read:messages'
        });

        jwtOptionsProvider.config({
            tokenGetter: function() {
              return localStorage.getItem('access_token');
            },
            whiteListedDomains: [
                baseApi.replace(/http[s]?:\/\//, ''),
                'qoqi8wqqac.execute-api.eu-west-1.amazonaws.com',
                'd-q8k5wtz9zg.execute-api.eu-west-1.amazonaws.com',
                'love.pinhornproperties.com',
                'localhost'
            ]
        });

        $httpProvider.interceptors.push('jwtInterceptor');

        $mdIconProvider.fontSet('md', 'material-icons');

        function scrollToTopOfPage() {
            window.scrollTo(0, 0);
        }

        $stateProvider
            .state('app', {
                abstract: true,
                component: 'ppaMain'
            })
            .state('app.overview', {
                url: '/',
                component: 'ppaDashboard',
                data: {
                    title: 'Dashboard'
                },
                onEnter: scrollToTopOfPage
            })
            .state('app.expense', {
                url: '/expense',
                component: 'ppaExpenseReport',
                data: {
                    addNewItem: 'expense',
                    title: 'Expenses'
                },
                onEnter: scrollToTopOfPage
            })
            .state('app.rent', {
                url: '/rent',
                component: 'ppaRentReport',
                data: {
                    title: 'Rents'
                }
            })
            .state('app.tenancy', {
                url: '/tenancy/report',
                component: 'ppaManageTenancies',
                data: {
                    addNewItem: 'tenancy',
                    title: 'Tenancies'
                },
                onEnter: scrollToTopOfPage
            })
            .state('app.debt', {
                url: '/debt',
                component: 'ppaDebtReport',
                data: {
                    addNewItem: 'debt',
                    title: 'Debts'
                },
                onEnter: scrollToTopOfPage
            })
            .state('app.tenant', {
                url: '/tenant',
                component: 'ppaManageTenants',
                data: {
                    addNewItem: 'tenant',
                    title: 'Tenants'
                },
                onEnter: scrollToTopOfPage
            })
            .state('app.callback', {
                url: '/callback',
                template: require('./templates/callback.pug'),
                controller: 'callbackCtrl as vm'
            });

        $locationProvider.html5Mode(true);

        $mdDateLocaleProvider.parseDate = function(dateString) {
            const m = moment(dateString, ['DD/MM/YYYY', 'D/M/YYYY'], true);
            return m.isValid() ? m.toDate() : new Date(NaN);
          };

        $mdDateLocaleProvider.formatDate = function(date) {

            if (!date) {
                return '';
            }

            const m = moment(date);
            return m.isValid() ? m.format('DD/MM/YYYY') : '';
        };

    }
]);

ngModule.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

ngModule
    .directive('ppaLogin', downgradeComponent({component: LoginComponent}) as angular.IDirectiveFactory)
    .directive('ppaPropertyAdd', downgradeComponent({component: PropertyAddComponent}) as angular.IDirectiveFactory)
    .directive('ppaDashboardGraph', downgradeComponent({component: DashboardGraphComponent}) as angular.IDirectiveFactory)
    .service('authService', AuthService)
    .service('propertyService', PropertyService);

import {AppModule} from './main.module';

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(platformRef => {
    // Intialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

    // Instruct UIRouter to listen to URL changes
    urlService.listen();
    urlService.sync();
  });
