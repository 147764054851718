
const ngModule = angular.module('ppa.services.menu', [
    'ppa.services.resources',
    'ppa.services.utils',
    'ppa.services.rx'
]);

ngModule.service('menuService', ['resources', 'utils', 'rx', function(resources, utils, rx) {

    var api = {};

    var toggle =  new rx.Subject();

    api.openMenu = function() {
        toggle.next(true);
    };

    api.closeMenu = function() {
        toggle.next(false);
    };

    api.onToggle = function() {
        return toggle.asObservable();
    };

    return api;

}]);