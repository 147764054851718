const ngModule = angular.module('ppa.widgets.main', [
    'ppa.widgets.fab',
    'ppa.services.ping',
    'ppa.services.menu'
]);

ngModule.component('ppaMain', {
    template: require('./main.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$rootScope', '$mdSidenav', 'pingService', 'menuService', function($scope, $rootScope, $mdSidenav, pingService, menuService){

        var vm = this;

        // -- initial state

        // -- api

        vm.overlayClick = menuService.closeMenu;

        // -- streams

        var menuToggleSubscription = menuService.onToggle().subscribe(function(open){
            if(open) {
                $mdSidenav('left-menu').open();
            } else {
                $mdSidenav('left-menu').close();
            }
        });

        // -- scope bindings

        $scope.$watch(function(){
            return $rootScope.loggedIn;
        }, function(loggedIn){
            vm.loggedIn = loggedIn;

            if(loggedIn === false) {
                pingService.ping();
            }
        });

        vm.$onDestroy = function() {
            menuToggleSubscription.unsubscribe();
        };


    }]
});