const ngModule = angular.module('ppa.widgets.tenant-form', []);

ngModule.component('ppaTenantForm', {
    template: require('./tenant-form.pug'),
    bindings: {
        tenant: '<',
        onSubmit: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function($scope) {

        var vm = this;

        // -- util functions

        function handleSuccess() {
            vm.feedbackEnabled = false;
            vm.form.$setPristine();

        }

        function handleFailure() {
            vm.processingError = true;
        }

        // -- api

        vm.submit = function() {
            if(vm.form.$valid) {
                vm.processing = true;
                vm.onSubmit(vm.tenant)
                    .then(handleSuccess, handleFailure)
                    .finally(function(){
                        vm.processing = false;
                        vm.feedbackEnabled = false;
                        vm.processingError = false;
                    });
            } else {
                vm.feedbackEnabled = true;
            }
        };
    }]
});
