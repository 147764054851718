const ngModule = angular.module('ppa.services.mask', []);

ngModule.factory('PreFormatters', [function() {
    function clearDelimitersAndLeadingZeros(value) {
        var cleanValue = value.replace(/^-/, '').replace(/^0*/, '');
        cleanValue = cleanValue.replace(/[^0-9]/g, '');
        return cleanValue;
    }

    function prepareNumberToFormatter(value, decimals) {
        return clearDelimitersAndLeadingZeros((parseFloat(value)).toFixed(decimals));
    }

    return {
        clearDelimitersAndLeadingZeros: clearDelimitersAndLeadingZeros,
        prepareNumberToFormatter: prepareNumberToFormatter
    };
}])
.factory('NumberValidators', [function() {
    return {
        maxNumber: function maxValidator(ctrl, value, limit) {
            var max = parseFloat(limit);
            var validity = ctrl.$isEmpty(value) || isNaN(max) || value <= max;
            ctrl.$setValidity('max', validity);
            return value;
        },
        minNumber: function minValidator(ctrl, value, limit) {
            var min = parseFloat(limit);
            var validity = ctrl.$isEmpty(value) || isNaN(min) || value >= min;
            ctrl.$setValidity('min', validity);
            return value;
        }
    };
}]);
