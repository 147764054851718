const ngModule = angular.module('ppa.widgets.debt-form', [
    'ppa.services.debt'
]);

ngModule.component('ppaDebtForm', {
    template: require('./debt-form.pug'),
    bindings: {
        debt: '<',
        units: '<',
        buildings: '<',
        clients: '<',
        onSubmit: '<'
    },
    controllerAs: 'vm',
    controller: ['debtService', function(debtService) {

        var vm = this;

        vm.form = {};

        // -- api

        vm.submit = function(){
            if(vm.form.$valid) {
                vm.processing = true;
                vm.onSubmit(vm.debt).finally(function(){
                    vm.processing = false;
                }).then(function(){
                    vm.form.$setPristine();
                });
            }
        };

    }]
});
