const ngModule = angular.module('ppa.widgets.segment-search', []);

ngModule.component('ppaSegmentSearch', {
    template: require('./segment-search.pug'),
    bindings: {
        segment: '=',
        sortAttribute: '=',
        segmentOptions: '<',
        attributeOptions: '<'
    },
    controllerAs: 'vm'
});
