const ngModule = angular.module('ppa.widgets.expense-add', [
    'ppa.services.rx',
    'ppa.services.expense',
    'ppa.services.observe',
    'ppa.services.snack',
    'ppa.widgets.expense-form'
]);

ngModule.component('ppaExpenseAdd', {
    template: require('./expense-add.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'rx', 'utils',  'expenseService', 'observeService', 'snackService',
    function($scope, rx, utils, expenseService, observeService, snackService) {

        var vm = this;

        // -- initial state

        vm.expense = {};

        // -- util functions

        function unsubscribe() {
            utils.unsubscribe(vm.stream);
        }

        function initialise(){
            vm.stream = rx.Observable.merge(
                observeService.properties(),
                observeService.clientsAndCategories(),
                observeService.debts()
            )
            .subscribe(function(data) {
                vm.categoriesMap = data.categoriesMap || vm.categoriesMap;
                vm.buildings = data.buildingsMap || vm.buildings;
                vm.units = data.units || vm.units;
                vm.clients = data.clients || vm.clients;
                vm.debts = data.debts || vm.debts;
                vm.ready = utils.isReady(vm.categoriesMap, vm.buildings, vm.units, vm.clients, vm.debts);
            });
        }

        function handleSuccess () {
            snackService.success('Expense saved');
            vm.expense = {};
            $scope.$broadcast('ppa.property-selector.clear');
        }

        function handleError () {
            snackService.error('An error occurred, could not save the expense');
        }

        // -- api

        vm.submitExpense = function(expense) {
            return expenseService.createExpense(expense)
                .then(handleSuccess, handleError);
        };

        // -- scope bindings

        // -- main

        vm.$onDestroy = function() {
            unsubscribe();
        };

        vm.$onInit = function() {
            initialise();
        };

    }]
});
