
const ngModule = angular.module('ppa.services.observe', [
    'ppa.services.rx',
    'ppa.services.utils',
    'ppa.services.client',
    'ppa.services.expense',
    'ppa.services.debt',
    'ppa.services.tenant',
    'ppa.services.tenancy'
]);

ngModule.service('observeService', ['rx', 'utils', 'propertyService', 'clientService', 'expenseService', 'debtService', 'tenantService', 'tenancyService',
    function(rx, utils, propertyService, clientService, expenseService, debtService, tenantService, tenancyService) {


    var api = {};

    var wrap = utils.rxWrap;
    var sort = utils.rxSort;

    api.properties = function(){
        var unitMapStream = propertyService.observeUnitsIdMap();
        var unitStream = propertyService.observeUnits();
        var buildingStream = propertyService.observeBuildingsIdMap();

        return rx.Observable.merge(
            buildingStream.share().pipe(wrap('buildingsMap')),
            unitMapStream.share().pipe(wrap('unitsMap')),
            unitStream.share().pipe(wrap('units'))
        );
    };

    api.clientsAndCategories = function() {
        var categoryStream = rx.Observable.fromPromise(expenseService.getExpenseCategories());
        var categoryMapStream = rx.Observable.fromPromise(expenseService.getExpenseCategoriesIdMap());
        var clientStream = clientService.observeClients();
        var clientMapStream = clientService.observeClientsIdMap();

        return rx.Observable.merge(
            categoryStream.pipe(sort('name'), wrap('categories')),
            categoryMapStream.pipe(wrap('categoriesMap')),
            clientStream.pipe(wrap('clients')),
            clientMapStream.pipe(wrap('clientsMap'))
        );
    };

    api.tenants = function() {
        return rx.Observable.merge(
            tenantService.observeTenants().pipe(wrap('tenants')),
            tenantService.observeTenantsIdMap().pipe(wrap('tenantsMap'))
        );
    };

    api.tenancies = function() {
        return rx.Observable.merge(
            tenancyService.observeTenancies().pipe(wrap('tenancies')),
            tenancyService.observeTenanciesIdMap().pipe(wrap('tenanciesMap'))
        );
    };

    api.debts = function() {
        return debtService.observeDebts().pipe(wrap('debts'));
    };

    return api;

}]);