const ngModule = angular.module('ppa.widgets.expense-category-selector', []);

ngModule.component('expenseCategorySelector', {
    template: require('./expense-category-selector.pug'),
    bindings: {
        category: '=',
        categories: '<',
        isRequired: '<',
        showLabel: '<',
        noCategoryEnabled: '<',
        onChange: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$q', function($scope, $q) {

        var vm = this;

        // -- initial state

        // -- util functions

        function unitBuilingQuery(buildings, query, unit) {
            var searchString = (unit.name + buildings[unit.building].name).toLowerCase();
            return searchString.indexOf(query.toLowerCase()) > -1;
        }

        // -- api

        vm.change = function(item) {
            vm.onChange({
                item: item
            });


        };

        vm.querySearch = function(query) {
            var search = unitBuilingQuery.bind(this, vm.buildings, query);
            return vm.units.filter(search);
        };

        //--Main

    }]
});
