
const ngModule = angular.module('ppa.services.utils', [
    'ppa.services.rx'
]);

ngModule.service('utils', ['rx', 'ppMoment', function(rx, ppMoment) {

    var api = {};

    api.ridGenerator = function(len) {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        var str = '';
        while (str.length < len) {
            str += S4();
        }
        return str.substring(0, len);
    };

    api.idMap = function(map, item) {
        map[item._id] = Object.assign({}, item);
        return map;
    };

    api.createIdMap = function(items) {
        return items.reduce(api.idMap, {});
    };

    api.groupBy = function(items, key) {
        return items.reduce(function(map, item){
            if(!item[key]){
                return map;
            }
            map[item[key]] = map[item[key]] || [];
            map[item[key]] = map[item[key]].concat([item]);
            return map;
        }, {});
    };

    api.rxWrap = function(key) {
        return rx.operators.map(function(item){
            var wrappedItem = {};
            wrappedItem[key] = item;
            return wrappedItem;
        });
    };

    api.rxSort = function(key) {
        return rx.operators.map(function(item){
            return item.sort(function(a, b){
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        });
    };

    api.unsubscribe = function(stream) {
        if(stream && stream.unsubscribe) {
            stream.unsubscribe();
        }
    };

    api.groupById = function(items) {
        return api.groupBy.bind(null, items, '_id')();
    };

    api.extractId = function(item) {
        if(item && angular.isObject(item)) {
            return item._id;
        }
    };

    api.sortByDate = function(dateKey, a, b) {
        return new Date(b[dateKey]) - new Date(a[dateKey]);
    };

    api.groupByMonth = function(dateKey, groups, expense) {
        var monthDate = ppMoment(expense[dateKey]).startOf('month').valueOf();
        groups[monthDate] = groups[monthDate] || [];
        groups[monthDate].push(expense);
        return groups;
    };

    api.sumAmounts = function(amountKey, total, expense) {
        return total + expense[amountKey];
    };

    api.isReady = function() {
        var args = Array.prototype.slice.call(arguments);
        return args.reduce(function(ready, item){
            if(!ready) {
                return ready;
            }

            return !!item;
        }, true);
    };

    var isArray = Array.isArray;

    function isObject(value) {
        return 'object' === typeof value;
    }

    function isDate(value) {
        return Object.prototype.toString.call(value) === '[object Date]';
    }

    function isRegExp(value) {
        return Object.prototype.toString.call(value) === '[object RegExp]';
    }

    function isString(value) {
        return 'string' === typeof value;
    }

    function isUndefined(value) {
        return 'undefined' === typeof value;
    }

    function extendHash(destination, source, deep) {
        var ix;
        var key;
        // bailout
        if (destination !== source) {
            // handles dates and regexps
            if (isDate(source)) {
                destination = new Date(source.getTime());
            } else if (isRegExp(source)) {
                destination = new RegExp(source.source, source.toString().match(/[^\/]*$/)[0]);
                destination.lastIndex = source.lastIndex;
            }
            // if source is object (or array) go recursive
            else if (isObject(source)) {
                // initialize as (or smash to) destination property to Array
                if (isArray(source)) {
                    if (!isArray(destination)) {
                        destination = [];
                    }
                    for (ix = 0; ix < source.length; ix++) {
                        destination.push(source[ix]);
                    }
                } else {
                    // initialize as (or smash to) destination property to Object
                    if (!isObject(destination) || isArray(destination)) {
                        destination = {};
                    }
                    for (key in source) {
                        destination[key] = deep ? extendHash(destination[key], source[key]) : source[key];
                    }
                }
            } else if (typeof source !== 'undefined') {
                destination = source;
            }
        }
        return destination;
    }

    api.extend = function() {
        var args = [].slice.call(arguments);
        var destination = args.shift();
        var deep;
        var source;
        if (destination === true) {
            deep = true;
            destination = args.shift();
        }
        while (source) {
            extendHash(destination, source, deep);
            source = args.shift();
        }
        return destination;
    };

    return api;

}]);