import {InjectionToken} from '@angular/core';

export const LEGACY_AUTH = new InjectionToken<any>('LEGACY_AUTH');
export const LEGACY_PROPERTY = new InjectionToken<any>('LEGACY_PROPERTY');


export function legacyAuthServiceFactory(i: any) {
  return i.get('authService');
}

export function legacyPropertyServiceFactory(i: any) {
  return i.get('propertyService');
}

export const legacyAuthServiceProvider = {
  provide: LEGACY_AUTH,
  useFactory: legacyAuthServiceFactory,
  deps: ['$injector']
};

export const legacyPropertyServiceProvider = {
  provide: LEGACY_PROPERTY,
  useFactory: legacyAuthServiceFactory,
  deps: ['$injector']
};
