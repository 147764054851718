
const ngModule = angular.module('ppa.services.ping', [
    'ppa.services.resources'
]);

ngModule.service('pingService', ['resources', function(resources) {

    var api = {};

    var PING_ENDPOINT = '/api/ping';

    api.ping = function(){
        return resources.httpGet(PING_ENDPOINT);
    };

    return api;

}]);