import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UpgradeModule} from '@angular/upgrade/static';
import {legacyAuthServiceProvider, legacyPropertyServiceProvider} from './services/ajs-upgraded-providers';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {HttpModule} from '@angular/http';
import {LoginModule} from './widgets/login/login.module';
import {LoginComponent} from './widgets/login/login';
import {PropertyAddModule} from './widgets/propertyAdd/property-add.module';
import {PropertyAddComponent} from './widgets/propertyAdd/property-add';
import {DashboardGraphModule} from './widgets/dashboardGraph/dashboard-graph.module';
import {DashboardGraphComponent} from './widgets/dashboardGraph/dashboard-graph';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    UpgradeModule,
    HttpModule,
    LoginModule,
    PropertyAddModule,
    DashboardGraphModule,
    UIRouterUpgradeModule.forChild({
      states: []
    })
  ],
  providers: [
    legacyAuthServiceProvider,
    legacyPropertyServiceProvider
  ],
  declarations: [],
  entryComponents: [
    LoginComponent,
    DashboardGraphComponent,
    PropertyAddComponent
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) {
  }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['ppa'], {strictDi: true});
  }
}
