import {Component, Input, OnChanges, SimpleChanges, SimpleChange} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as Moment from 'moment';
import {map, compose, merge, reduce, values, path, not, isNil, sort, concat, defaultTo, last} from 'ramda';
import {IncomingPayment, Expense, Transaction, DateGraphPoint, isIncomingPayment, isExpense} from '../../types';



@Component({
  selector: 'ppa-dashboard-graph',
  template: require('./dashboard-graph.html'),
  styles: [``]
})
export class DashboardGraphComponent implements OnChanges {

    @Input() expenses: Expense[];
    @Input() rents: IncomingPayment[];
    updateFlag: Boolean = false;
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    constructor() {

    }

    private sortByX(a: DateGraphPoint, b: DateGraphPoint) {
        return a.x - b.x;
    }

    private totalByMonth(items: Transaction[]): DateGraphPoint[] {
        return compose(
            sort(this.sortByX),
            values,
            reduce<Transaction, {[key: number]: DateGraphPoint}>((acc, item) => {
                if (acc[item.month]) {
                    acc[item.month].y += item.amount;
                } else {
                    acc[item.month] = {
                        y: item.amount,
                        x: item.month
                    };
                }
                return acc;
            }, {}),
            map<Transaction, Transaction>((item) => {
                let month;

                if (isExpense(item)) {
                    month = {month: Number(Moment.utc(item.date).startOf('month').valueOf())};
                } else if (isIncomingPayment(item)) {
                    month = {month: Number(Moment.utc(item.datePaid).startOf('month').valueOf())};
                }

                return merge(
                    item,
                    month
                );
            })
        )(items);
    }

    private cumulativeTotal(rents: DateGraphPoint[], expenses: DateGraphPoint[]): DateGraphPoint[] {
        const negExpenses: DateGraphPoint[] = expenses.map(item => {
            return {
                y: item.y * -1,
                x: item.x
            };
        });

        return compose(
            reduce((acc, item: DateGraphPoint) => {
                return acc.concat([{x: item.x, y: item.y + Number(defaultTo(0, path(['y'], last(acc))))}]);
            }, []),
            values,
            reduce((acc, item: DateGraphPoint) => {
                if (acc[item.x]) {
                    acc[item.x].y += item.y;
                } else {
                    acc[item.x] = {
                        y: item.y,
                        x: item.x
                    };
                }
                return acc;
            }, {}),
            sort(this.sortByX)
        )(concat(rents, negExpenses));
    }

    private profitLossLine(rents: DateGraphPoint[], expenses: DateGraphPoint[]): DateGraphPoint[] {
        const negExpenses: DateGraphPoint[] = expenses.map(item => {
            return {
                y: item.y * -1,
                x: item.x
            };
        });

        return compose(
            values,
            reduce((acc, item: DateGraphPoint) => {
                if (acc[item.x]) {
                    acc[item.x].y += item.y;
                } else {
                    acc[item.x] = {
                        y: item.y,
                        x: item.x
                    };
                }
                return acc;
            }, {}),
            sort(this.sortByX)
        )(concat(rents, negExpenses));
    }

    private updateGraph(rents: DateGraphPoint[], expenses: DateGraphPoint[]) {
        const cumProfitLossLine = this.cumulativeTotal(rents, expenses);
        const profitLossLine = this.profitLossLine(rents, expenses);

    this.chartOptions = {
        chart: {
            renderTo: 'ppa-dashboard-graph'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormatter: function() {
               return `<b>${this.series.name}</b> £${Highcharts.numberFormat(this.y, 2, '.', ',')}`;
            }
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                formatter: function() {
                    return '£' + Highcharts.numberFormat(this.value, 0, '.', ',');
                }
            }
        },
        series: [{
            name: 'Rents',
            data: rents,
            color: '#009688',
            type: 'column'
        }, {
            name: 'Expenses',
            data: expenses,
            type: 'column',
            color: '#F44336'
        },
        {
            name: 'Cumulative Profit / Loss',
            data: cumProfitLossLine,
            type: 'spline'
        },
        {
            name: 'Profit / Loss',
            data: profitLossLine,
            type: 'spline'
        }],
        credits: {
            enabled: false
        }
    };
    this.updateFlag = true;

    }


    ngOnChanges(changes: SimpleChanges): void {
    const expenses: Expense[] =  path<SimpleChange>(['expenses'], changes).currentValue;
    const rents: IncomingPayment[] =  path<SimpleChange>(['rents'], changes).currentValue;
    if (not(isNil(expenses)) && not(isNil(rents))) {
        this.updateGraph(
            this.totalByMonth(rents),
            this.totalByMonth(expenses)
        );
    }

    }
}



