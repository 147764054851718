
const ngModule = angular.module('ppa.services.client', [
    'ppa.services.resources',
    'ppa.services.utils',
    'ppa.services.rx'
]);

ngModule.service('clientService', ['$q', '$cacheFactory', 'resources', 'utils', 'rx', function($q, $cacheFactory, resources, utils, rx) {

    var api = {};

    var CLIENT_ENDPOINT = '/api/clients';

    var subject =  new rx.Subject();

    var idMapSubject = new rx.Subject();

    var promiseCache = $cacheFactory('client');

    function getClients(fetch) {
        var cacheKey = 'clients';
        if(fetch || !promiseCache.get(cacheKey)) {
            var promise = resources.httpGet(CLIENT_ENDPOINT, null, 'clients', fetch)
                .catch(function(err){
                    promiseCache.remove(cacheKey);
                    return $q.reject(err);
                });
            promiseCache.put(cacheKey, promise);
        }

        return promiseCache.get(cacheKey);
    }

    api.getClients = getClients;

    api.refreshClients = function() {
        return getClients(true).then(function(clients){
            subject.next(clients);
            idMapSubject.next(clients.reduce(utils.idMap, {}));
        }, function(err){
            subject.onError(err);
        });
    };

    api.getClientsIdMap = function(){
        return getClients().then(function(clients){
            return utils.createIdMap(clients);
        });
    };

    api.updateClient = function(client){
        var endpoint = CLIENT_ENDPOINT + '/'+ client._id;
        return resources.httpPut(endpoint, client).then(function(res){
            api.refreshClients();
            return res;
        });
    };

    api.addClient = function(client) {
        return resources.httpPost(CLIENT_ENDPOINT, client).then(function(res){
            api.refreshClients();
            return res;
        });
    };

    api.observeClients = function() {
        var promise = rx.Observable.fromPromise(getClients());
        var stream = subject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.observeClientsIdMap = function() {
        var promise = rx.Observable.fromPromise(api.getClientsIdMap());
        var stream = idMapSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.purgeCache = function() {
        promiseCache.removeAll();
    };

    return api;

}]);