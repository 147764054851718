
const ngModule = angular.module('ppa.services.date', [
    'ppa.services.preference',
    'ppa.services.tax'
]);

ngModule.service('dateService', ['$q', 'preferenceService', 'taxService', function($q, preferenceService, taxService) {

    var FROM_DATE_PREFERNCE_KEY = 'date-range.from-date';
    var TO_DATE_PREFERNCE_KEY = 'date-range.to-date';

    var api = {};

    api.getDateRange = function() {

        var promises = {
            fromDate: preferenceService.getPreference(FROM_DATE_PREFERNCE_KEY),
            toDate: preferenceService.getPreference(TO_DATE_PREFERNCE_KEY)
        };

        return $q.all(promises).then(function(dates){
            if(!dates.fromDate || !dates.toDate) {
                return taxService.currentTaxYear();
            }

            return {
                toDate: dates.toDate,
                fromDate: dates.fromDate
            };
        })
        .catch(taxService.currentTaxYear);
    };


    return api;

}]);