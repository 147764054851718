const ngModule = angular.module('ppa.services.dialog', [
    'ngMaterial'
]);

ngModule.service('dialogService', ['$rootScope', '$document', '$window', '$compile', '$q', '$mdDialog', '$timeout', function ($rootScope, $document, $window, $compile, $q, $mdDialog, $timeout) {

    var api = {};
    var DIALOG_POPUP_DELAY = 300;

    function handlePromise(promise) {
        return promise.then(angular.noop, angular.noop);
    }

    function createContent(html, pureDialog) {
        if(pureDialog) {
            return '<md-dialog>' + html + '</md-dialog>';
        } else {
            return '<md-dialog><div><md-toolbar-tools></md-toolbar-tools><md-dialog-content><div class="md-dialog-content">' + html + '</div></md-dialog-content><div></md-dialog>';
        }
    }

    api.show = function (options, pureDialog) {

        // create a new scope and expose
        var childScope = options.scope ? options.scope.$new() : $rootScope.$new();

        // compile
        var classNames = 'jquery-modal ' + options.classNames;

        var contents = createContent(options.contents, pureDialog);

        return $timeout(function(){

            handlePromise($mdDialog.show({
              template: contents,
              scope: childScope,
              clickOutsideToClose: true,
              openFrom: options.openFrom,
              closeTo: options.openFrom
            }));
        }, DIALOG_POPUP_DELAY);

        // append
    };

    api.close = function () {
        $mdDialog.hide();
    };

    return api;
}]);
