const ngModule = angular.module('ppa.widgets.rent-reconciliation', [
    'ppa.services.dialog',
    'ppa.widgets.rent-card'
]);

ngModule.component('ppaRentReconciliation', {
    template: require('./rent-reconciliation.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['rentService', 'dialogService', 'ppMoment', function(rentService, dialogService, ppMoment) {
        var vm = this;

        // initial state

        vm.randomCelebrationImage = '/images/celebration/' + Math.floor(Math.random() * 10) + '.gif';

        // -- util functions


        // TODO handle error in fetching rents
        function setupRents() {
            return rentService.getRentCards().then(function(rents) {
                vm.rents = rents;
                return rents;
            },
            function() {
                vm.rents = [];
                return vm.rents;
            });
        }

        // -- api

        vm.addPayment = function(payment, rent, property) {
            payment.rent = rent;
            payment.property = property;
            payment.datePaid = ppMoment(payment.datePaid).format("YYYY-MM-DD");
            return rentService.postPayment(payment).then(function() {

                return setupRents();
            });
        };

        vm.updateRent = function(rent) {
            vm.processing = true;
            return rentService.updateRent(rent._id, rent).then(function() {
                return setupRents();
            }).then(function(){
                vm.processing = false;
            });
        };

        vm.deleteRent = function(id) {
            return rentService.deleteRent(id).then(function() {
                vm.ready = false;
                return setupRents();
            }).then(function(){
              vm.ready = true;
            });
        };

        vm.openDialog = function(contents, scope, element) {
            var options = {
                contents: contents,
                scope: scope,
                openFrom: element
            };
            return dialogService.show(options);
        };

        // -- scope bindings

        // -- main

        setupRents().then(function(rents){
            if(rents.length) {
                vm.ready = true;
            } else {
                return rentService.getCelebrationVideo();
            }

        }).then(function(video){
            vm.celebrationVideo = video;
            vm.ready = true;
        });
    }]
});
