
var STORE_KEY_PREFIX = 'ppa-store.';

const ngModule = angular.module('ppa.services.storage', []);

ngModule.service('storageService', ['$window', function ($window) {
    var api = {};

    api.set = function (key, value) {
        var keyStore = STORE_KEY_PREFIX + key;
        $window.localStorage.setItem(keyStore, value);
    };


    api.setJson = function(key, value) {
        var jsonValue = JSON.stringify(value);
        api.set(key, jsonValue);
    };

    api.get = function (key) {
        var keyStore = STORE_KEY_PREFIX + key;
        return $window.localStorage.getItem(keyStore);
    };

    api.getJson = function(key, value) {
        try {
            return JSON.parse(api.get(key));
        } catch(err) {
            return null;
        }
    };

    api.delete = function (key) {
        var keyStore = STORE_KEY_PREFIX + key;
        return $window.localStorage.removeItem(keyStore);
    };

    return api;
}]);
