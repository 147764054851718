const ngModule = angular.module('ppa.widgets.tenancy-card', [
    'ppa.services.tenancy'
]);

ngModule.component('ppaTenancyCard', {
    template: require('./tenancy-card.pug'),
    bindings: {
        tenancy: '<',
        tenancyAttributes: '<',
        isMenuOpen: '<',
        isEditing: '<',
        isClosing: '<',
        onEditTenancy: '&',
        onCloseTenancy: '&',
        onToggleMenu: '&'
    },
    controllerAs: 'vm',
    controller: ['tenancyService', function(tenancyService) {

        var vm = this;

        // -- intial state

        // -- util functions

        // -- api

        vm.toggleMenu = function(){
            vm.onToggleMenu({
                tenancy: angular.copy(vm.tenancy)
            });
        };

        vm.closeTenancy = function(){
            vm.onCloseTenancy({
                tenancy: angular.copy(vm.tenancy)
            });
        };

        vm.editTenancy = function(){
            vm.onEditTenancy({
                tenancy: angular.copy(vm.tenancy)
            });
        };


        // -- main




    }]
});
