
const ngModule = angular.module('ppa.services.tax', [
    'ppa.services.resources'
]);

ngModule.service('taxService', ['$q', 'resources', function($q, resources) {

    var api = {};

    function normaliseDates(dates) {
        return {
            fromDate: dates[0],
            toDate: dates[1]
        };
    }

   api.currentTaxYear = function() {
        var currentTime = new Date();
        var day = currentTime.getDate();
        var month = currentTime.getMonth() + 1;
        var year = currentTime.getFullYear();
        if (month < 4) {
            return normaliseDates([(year - 1) + "-04-06", year + "-04-05"]);
        } else if (month > 4) {
            return normaliseDates([year + "-04-06", (year + 1) + "-04-05"]);
        } else {
            if (day < 5) {
                return normaliseDates([(year - 1) + "-04-06", year + "-04-05"]);
            } else {
                return normaliseDates([(year - 1) + "-04-06", year + "-04-05"]);
            }
        }
    };

    return api;

}]);

