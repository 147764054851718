const ngModule = angular.module('ppa.widgets.dashboard', [
    'ppa.services.rx',
    'ppa.services.utils',
    'ppa.services.rent',
    'ppa.services.expense',
    'ppa.services.date',
    'ppa.widgets.rent-reconciliation',
    'ppa.widgets.date-range'
]);

ngModule.component('ppaDashboard', {
    template: require('./dashboard.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'rx', 'utils', 'rentService', 'expenseService', 'dateService', function($scope, rx, utils, rentService, expenseService, dateService){

        var vm = this;


        // -- util functions

        function init(startDate, endDate) {
            return rx.Observable.combineLatest(
                rentService.observePaymentsForReport(startDate, endDate),
                expenseService.observeExpensesForDateRange(startDate, endDate)
            )
            .subscribe(function(res){
                vm.totals = {
                    incomings: res[0].reduce(utils.sumAmounts.bind(null, 'amount'), 0),
                    outgoings: res[1].reduce(utils.sumAmounts.bind(null, 'amount'), 0)
                };
                vm.rents = res[0];
                vm.expenses = res[1];
                vm.profitLoss = vm.totals.incomings - vm.totals.outgoings;
                vm.ready = true;

            });

        }

        // -- api

        // -- scope bingins

        $scope.$on('dateChanged', function(event, dateRange){
            vm.dateRange = dateRange;
            init(dateRange.fromDate, dateRange.toDate);
        });


        // -- main

        vm.$onDestroy = function(){
            utils.unsubscribe(vm.stream);
        };

        dateService.getDateRange().then(function(dateRange){
            vm.dateRange = dateRange;
            vm.stream = init(dateRange.fromDate, dateRange.toDate);
        });



    }]
});