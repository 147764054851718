
const ngModule = angular.module('ppa.services.expense', [
    'ppa.services.rx',
    'ppa.services.resources',
    'ppa.services.utils'
]);

ngModule.service('expenseService', ['$q', '$filter', '$cacheFactory', 'rx', 'resources', 'propertyService', 'utils', 'ppMoment', function($q, $filter, $cacheFactory, rx, resources, propertyService, utils, ppMoment) {

    var api = {};

    var EXPENSE_ENDPOINT = '/api/expenses';
    var EXPENSE_CATEGORY_ENDPOINT = '/api/expensecategories';

    var REPORT_DOWNLOAD_ENDPOINT = '/service/download-report';

    var DATE_FORMAT = 'yyyy-MM-dd';

    var promiseCache = $cacheFactory('expense');

    var expenseDateRangeSubject =  new rx.Subject();
    var debtExpenseSubject = new rx.Subject();

    var __refreshExpenses;

    var wrap = utils.rxWrap;

    api.getExpenseCategories = function() {
        return resources.httpGet(EXPENSE_CATEGORY_ENDPOINT);
    };

    api.getExpenseCategoriesIdMap = function() {
        return api.getExpenseCategories().then(utils.createIdMap);
    };

    function getDateRangeQuery(startDate, endDate) {
        return [{
            where: 'date',
            value: $filter('date')(startDate, DATE_FORMAT),
            comparator: '>=',
            type: 'date'
        },
        {
            where: 'date',
            value: $filter('date')(endDate, DATE_FORMAT),
            comparator: '<=',
            type: 'date'
        }];
    }

    function getPropertyQuery(propertyId) {
        return [{
            where: 'property',
            value: propertyId
        }];
    }

    function getCategoryQuery(categoryId) {
        return [{
            where: 'category',
            value: categoryId
        }];
    }

    function getExpenses(query) {
        return resources.httpGet(EXPENSE_ENDPOINT, query, null, true);
    }

    function constructGetExpenseQuery(startDate, endDate, property, category) {
        var query = [];

        if(startDate && endDate) {
            query = query.concat(getDateRangeQuery(startDate, endDate));
        }

        if(property) {
            query = query.concat(getPropertyQuery(property));
        }

        if(category) {
            query = query.concat(getCategoryQuery(category));
        }

        return query;
    }

    function isDebtRelated(category) {
        return category.debtRelated;
    }

    function isRepaymentCategory(category) {
        return isDebtRelated(category) && category.name.toLowerCase().indexOf('capital') > -1;
    }

    function getDebtExpenses() {
        return api.getExpenseCategories().then(function(categories){
            var query = categories
                .filter(isRepaymentCategory)
                .map(function(category){
                    return {
                        where: 'category',
                        value: category._id
                    };
                });
            return getExpenses(query);
        })
        .then(function(expenses) {
            return utils.groupBy(expenses, 'debt');
        });
    }

    api.refreshDebtExpense = function() {
        getDebtExpenses().then(function(expenses){
            debtExpenseSubject.next(expenses);
        });
    };

    api.refreshExpenses = function() {
        if(__refreshExpenses && angular.isFunction(__refreshExpenses)) {
            __refreshExpenses();
            api.refreshDebtExpense();
        }
    };

    api.getExpenses = function(query) {
        return getExpenses(query);
    };

    api.getExpensesForDateRange = function(startDate, endDate, property, category) {

        __refreshExpenses = function() {
            var query = constructGetExpenseQuery(startDate, endDate, property, category);
            return api.getExpenses(query).then(function(expenses){
                expenseDateRangeSubject.next(expenses);
                return expenses;
            });
        };

        return __refreshExpenses;
    };

    api.getExpensesTotalForDateRangeGroupedByCategory = function(startDate, endDate) {
        var promises = {
            expenses: api.getExpensesForDateRange(startDate, endDate),
            categories: api.getExpenseCategoriesIdMap()
        };

        return $q.all(promises).then(function(res){
            return Object.entries(utils.groupBy(res.expenses, 'category')).map(function(expensesForCategory){
                return {
                    category: res.categories[expensesForCategory[0]],
                    total: expensesForCategory.slice(1)[0].reduce(utils.sumAmounts.bind(null, 'amount'), 0)
                };
            });
        });
    };

    api.createExpense = function(expense) {
        return resources.httpPost(EXPENSE_ENDPOINT, expense)
            .then(api.refreshExpenses);
    };

    api.updateExpense = function(expense) {
        var endpoint = EXPENSE_ENDPOINT + '/' + expense._id;
        return resources.httpPut(endpoint, expense)
            .then(api.refreshExpenses);
    };

    api.deleteExpense = function(expenseId) {
        var endpoint = EXPENSE_ENDPOINT + '/' + expenseId;
        return resources.httpDelete(endpoint, expenseId)
            .then(api.refreshExpenses);
    };

    function filterByCategoryId(categoryId) {
        return function (expense) {
            return expense.category === categoryId;
        };
    }

    api.observeExpensesForDateRange = function(startDate, endDate, property, category) {
        var promise = rx.Observable.fromPromise(api.getExpensesForDateRange(startDate, endDate, property, category)()).pipe(rx.operators.distinctUntilChanged());
        var stream = expenseDateRangeSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.observeDebtExpenses = function() {
        var promise  = rx.Observable.fromPromise(getDebtExpenses());
        var stream = debtExpenseSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    function normaliseReportDownload(buildings, units, categories, clients, item) {
        const client  = clients[item.client] || {};
        const category =  categories[item.category] || {};
        return {
            date_paid: ppMoment(item.date).format(),
            property_name: propertyService.getBuildingOrPropertyName(buildings, units, item.property, item.building),
            client: client.name,
            category: category.name,
            details: item.details,
            amount: item.amount
        };
    }



    api.getDownloadUrl = function(expenses, startDate, endDate, buildings, units, categories, clients) {

       var payload = {
            data: expenses.map(normaliseReportDownload.bind(this, buildings, units, categories, clients)),
            fileName: 'expense-report-' + startDate + '-' + endDate,
            format: 'csv'
        };

        return resources.httpPost(REPORT_DOWNLOAD_ENDPOINT, payload);

    };

    api.purgeCache = function() {
        promiseCache.removeAll();
    };


    return api;

}]);