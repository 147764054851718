
const ngModule = angular.module('ppa.services.tenancy', [
    'ppa.services.rx',
    'ppa.services.resources',
    'ppa.services.tenant',
    'ppa.services.utils'
]);

ngModule.service('tenancyService', ['$q', 'rx', 'resources', 'propertyService', 'tenantService', 'utils', 'ppMoment', function($q, rx, resources, propertyService, tenantService, utils, ppMoment) {

    var tenancyTypeMap = {
        AST: 'Assured Shorthold Tenancy',
        COMMERCIALLEASE: 'Commercial Lease',
        PROTECTED: 'Protected Tenancy'
    };

    var subject =  new rx.Subject();
    var idMapSubject =  new rx.Subject();

    var api = {};

    var TENANCY_ENDPOINT = '/api/tenancies';

    var INSERT_RENT_ENDPOINT = '/api/service/rent-insert/:id';

    var getTenancies = resources.httpGet.bind(this, TENANCY_ENDPOINT, null, null);

    function isTenancyExpiring(tenancy) {
        return !tenancy.closed && tenancy.type === 'AST' && ppMoment(tenancy.contractEndDate).isBefore(ppMoment().add(2, 'M')) && ppMoment(tenancy.contractEndDate).isAfter(ppMoment());
    }

    function addSegmentsToTenancy(tenancy){

        tenancy.segments = [];
        tenancy.segments.push(tenancyTypeMap[tenancy.type]);

        if(!tenancy.closed && tenancy.type === 'AST' && ppMoment(tenancy.contractEndDate).isBefore(ppMoment())){
            tenancy.segments.push('statutory periodic');
        }

        if(isTenancyExpiring(tenancy)){
            tenancy.segments.push('expiring');
        }

        if(tenancy.closed){
            tenancy.segments.push('closed');
        } else {
            tenancy.segments.push('open');
        }

        return tenancy;

    }

    function tenancyTitle(units, buildings, tenants) {
        return function(tenancy) {
            var unit = units[tenancy.property] || {};
            var building = buildings[unit.building] || {};
            var tenancyTenants = [];

            if(angular.isArray(tenancy.tenants)){
                tenancyTenants = tenancy.tenants.map(function(tenant){
                    return tenants[tenant];
                });
            }

            var tenantList = tenancyTenants.reduce(function(names, tenant){
                return names + ' ' + tenant.firstName + ' ' + tenant.lastName + ',';
            }, '');

            tenantList = tenantList.substring(0, tenantList.length - 1);
            var property = building.name + ', ' + unit.name;
            return tenantList + ' - ' + property;
        };
    }

    function updateTenancy(tenancy) {
        var endpoint = TENANCY_ENDPOINT + '/'+ tenancy._id;
        return resources.httpPut(endpoint, tenancy);
    }

    function refreshTenancies() {
        return api.getTenancies(true).then(function(tenancies){
            subject.next(tenancies);
            idMapSubject.next({
                list: tenancies.list.reduce(utils.idMap, {}),
                attributes: tenancies.attributes
            });
        });
    }

    api.getHumanReadableTenancyType = function(type){
        return tenancyTypeMap[type];
    };

    api.filterOverdueTenancies = function(tenancies) {
        return tenancies.filter(isTenancyExpiring);
    };

    api.createTenancyAttributes = function(units, buildings, tenants, tenancies) {
        var createTenancyTitle = tenancyTitle(units, buildings, tenants);
        return tenancies.reduce(function(attributes, tenancy) {

            attributes[tenancy._id] = {
                title: createTenancyTitle(tenancy),
                typeLabel: tenancyTypeMap[tenancy.type]
            };

            return attributes;
        }, {});
    };

    api.refreshTenancies = refreshTenancies;

    api.getTenancies = function(fetch) {
        var promises = {
            tenancies: getTenancies(fetch),
            unitsMap: propertyService.getUnitsIdMap(),
            buildingsMap: propertyService.getBuildingsIdMap(),
            tenantsMap: tenantService.getTenantsIdMap()
        };

        return $q.all(promises).then(function(responses){
            var attributes = api.createTenancyAttributes(responses.unitsMap, responses.buildingsMap, responses.tenantsMap, responses.tenancies);
            return {
                list: responses.tenancies.map(addSegmentsToTenancy),
                attributes: attributes
            };
        });

    };

    api.getTenanciesIdMap = function(fetch) {
        return getTenancies(fetch).then(function(tenancies){
            return utils.createIdMap(tenancies);
        });
    };



    api.recreateTenancies = function() {
        return api.getTenancies().then(function(tenancies){
            subject.next(tenancies);
            idMapSubject.next(tenancies.list.reduce(utils.idMap, {}));
        });
    };

    api.observeTenancies = function(){
        var promise = rx.Observable.fromPromise(api.getTenancies());
        var stream = subject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.observeTenanciesIdMap = function(){
        var promise = rx.Observable.fromPromise(api.getTenanciesIdMap());
        var stream = idMapSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.getAllSegmentOptionsFromTenancies = function(tenancies) {

        if(!tenancies) {
            return [];
        }

        return Object.keys(tenancies.reduce(function(segments, tenancy){

            return Object.assign(segments, tenancy.segments.reduce(function(segmentObject, segment){
                segmentObject[segment] = true;
                return segmentObject;
            }, {}));

        }, {})).sort((a, b) => {
            return a > b ? 1 : -1;
        });
    };

    api.insertRentsForTenancy = function(tenancyId) {
        var endpoint = INSERT_RENT_ENDPOINT.replace(':id', tenancyId);
        return resources.httpPost(endpoint, {});
    };

    api.addTenancy = function(tenancy) {
        var endpoint = TENANCY_ENDPOINT;
        var payload = tenancy;
        payload.closed = false;

        return resources.httpPost(endpoint, payload).then(function(tenancy){
            return $q.all(tenancy.insertedIds.map(api.insertRentsForTenancy));
        })
        .then(
            refreshTenancies,
            refreshTenancies
        );
    };

    api.updateTenancy = function(tenancy) {
        return updateTenancy(tenancy).then(function(){
            refreshTenancies();
        });
    };

    api.closeTenancy = function(tenancy) {
        var payload = angular.copy(tenancy);
        payload.closed = true;
        return updateTenancy(payload).then(function(){
            refreshTenancies();
        });
    };

    api.isPropertyTenanted = function(propertyId, tenancies) {

        if(!angular.isArray(tenancies)) {
            return false;
        }

        return tenancies.filter(function(tenancy){
            return !tenancy.closed;
        }).reduce(function(isTenanted, tenancy){
            return isTenanted || tenancy.property === propertyId;
        }, false);
    };


    api.purgeCache = function(){
        resources.purgeCache('.api.tenancies');
    };

    rx.Observable.merge(
        tenantService.idMapStream,
        propertyService.unitIdMapStream,
        propertyService.buildingIdMapStream
    ).subscribe(function(){
        api.recreateTenancies();
    });


    return api;

}]);
