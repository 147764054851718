
const ngModule = angular.module('ppa.services.cache', []);

ngModule.service('cacheService', [
    '$cacheFactory',
    function(
        $cacheFactory
    ) {


    var api = {};

    api.purgeAll = function() {
        angular.forEach($cacheFactory.info(), function(item, key) {
            if(item.id === 'templates') {
                return;
            }

            $cacheFactory.get(item.id).removeAll();
        });
    };


    return api;

}]);