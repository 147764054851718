
const ngModule = angular.module('ppa.services.tenant', [
    'ppa.services.resources',
    'ppa.services.utils',
    'ppa.services.rx'
]);

ngModule.service('tenantService', ['$cacheFactory', 'resources', 'utils', 'rx', function($cacheFactory, resources, utils, rx) {

    var api = {};

    var TENANT_ENDPOINT = '/api/tenants';

    var promiseCache = $cacheFactory('tenant');

    var subject =  new rx.Subject();
    var idMapSubject =  new rx.Subject();

    api.getTenants = function(fetch) {
        var cacheKey = 'tenants';
        if(fetch || !promiseCache.get(cacheKey)) {
            var promise = resources.httpGet(TENANT_ENDPOINT, null, null, fetch)
                .catch(function(tenants){
                    promiseCache.remove(cacheKey);
                });
            promiseCache.put(cacheKey, promise);
        }

        return promiseCache.get(cacheKey);
    };

    api.getTenantsIdMap = function() {
        return api.getTenants().then(function(tenants){
            return utils.createIdMap(tenants);
        });
    };

    api.refreshTenants = function() {
        return api.getTenants(true).then(function(tenants){
            subject.next(tenants);
            idMapSubject.next(tenants.reduce(utils.idMap, {}));
        }, function(err){
            subject.onError(err);
            idMapSubject.onError(err);
        });
    };

    api.observeTenants = function() {
        var promise = rx.Observable.fromPromise(api.getTenants());
        var stream = subject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.observeTenantsIdMap = function() {
        var promise = rx.Observable.fromPromise(api.getTenantsIdMap());
        var stream = idMapSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.idMapStream = idMapSubject.asObservable().pipe(rx.operators.distinctUntilChanged());

    api.addTenant = function(tenant) {
        var endpoint = TENANT_ENDPOINT;
        return resources.httpPost(endpoint, tenant).then(function(){
            api.refreshTenants();
        });
    };

    api.updateTenant = function(tenant) {
        var endpoint = TENANT_ENDPOINT + '/'+ tenant._id;
        return resources.httpPut(endpoint, tenant).then(function(){
            api.refreshTenants();
        });
    };

    api.purgeCache = function() {
        promiseCache.removeAll();
    };


    return api;

}]);