var MENU_CLOSE_DELAY = 300;

const ngModule = angular.module('ppa.widgets.top-bar', []);

ngModule.component('ppaTopBar', {
    template: require('./top-bar.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$timeout', '$state', '$transitions', 'menuService', function($timeout, $state, $transitions, menuService) {

        var vm = this;

        // -- util functions

        function getTitle(data) {
            return data && data.title;
        }

        // -- api

        vm.openMenu = menuService.openMenu;

        // -- scope bindings

        var unsubscribeTransitionHook = $transitions.onStart({}, function(transition) {
            var toState = transition.to();

            vm.title = getTitle(toState.data);

            $timeout(function(){
                menuService.closeMenu();
            }, MENU_CLOSE_DELAY);
        });


        vm.title = getTitle($state.current.data);

        // -- lifecycle

        vm.$onDestroy = function() {
            unsubscribeTransitionHook();
        };


    }]
});
