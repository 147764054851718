import {ILocationService} from 'angular';
import * as auth0 from 'auth0-js';



export class AuthService {

  static $inject = [
    '$window',
    '$state',
    'storageService',
    'jwtHelper'
  ];

  auth0 = new auth0.WebAuth({
      clientID: '3DeUZWBg4K6ZmatfR0inhH58queFNIR1',
      domain: 'egarof.eu.auth0.com',
      responseType: 'token id_token',
      redirectUri: this.$window.location.protocol + '//' + this.$window.location.host + '/callback',
       audience: 'https://pinhornproperties.com',
      scope: 'openid profile email read:messages'
  });

  constructor(
    private $window: any,
    private $state: any,
    private storageService: any,
    private jwtHelper: any
  ) {}

     login() {
      this.auth0.authorize();
    }

    setSession(authResult) {
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('access_token', authResult.accessToken);
      this.storageService.setJson('user', this.jwtHelper.decodeToken(authResult.accessToken));
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);
    }

    handleAuthentication() {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          this.$state.go('app.overview');
        } else if (err) {
          console.log(err);
        }
      });
    }

    logout() {
      // Remove tokens and expiry time from localStorage
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
    }

    isAuthenticated() {
      // Check whether the current time is past the
      // access token's expiry time
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      return new Date().getTime() < expiresAt;
    }
}







