const ngModule = angular.module('ppa.widgets.tenancy-form', []);

ngModule.component('ppaTenancyForm', {
    template: require('./tenancy-form.pug'),
    bindings: {
        tenancy: '<',
        tenants: '<',
        buildings: '<',
        units: '<',
        tenancies: '<',
        tenancyAttributes: '<',
        onSubmit: '&',
        disablePropertySelect: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'tenancyService', function($scope, tenancyService) {
        var vm = this;

        // -- api

        vm.submit = function() {
            if (vm.form.$valid) {
                var tenancy = angular.copy(vm.tenancy);

                vm.processing = true;
                vm.onSubmit({
                    tenancy: tenancy
                })
                .then(function(){
                    vm.form.$setPristine();
                })
                .finally(function(){
                    vm.processing = false;
                });
            }
        };


        // -- scope bindings

        $scope.$watch('vm.tenancy.property', function(property) {
            if(!property) {
                vm.isPropertyTenanted = false;
                return;
            }
            vm.isPropertyTenanted = tenancyService.isPropertyTenanted(property, vm.tenancies);
        });

        // -- main


    }]
});
