  const ngModule = angular.module('ppa.controllers.callback', []);

  ngModule.controller('callbackCtrl', [
      function() {

        var vm = this;

        // -- Initial state

        // -- Util functions

        // -- api


        // -- main


      }
  ]);
