const ngModule = angular.module('ppa.widgets.tenant-add', [
    'ppa.services.tenant',
    'ppa.services.snack',
    'ppa.widgets.tenant-form'
]);

ngModule.component('ppaTenantAdd', {
    template: require('./tenant-add.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'tenantService', 'snackService', function($scope, tenantService, snackService) {

        var vm = this;

        vm.tenant = {};

        // -- util functions

        function showSuccess() {
            snackService.error('Tenant saved');
            vm.tenant = {};
        }

        function showError() {
            snackService.error('Unable to save tenant');
        }

        function addTenant(tenant) {
            return tenantService.addTenant(tenant)
                .then(showSuccess, showError);
        }

        // -- api

        vm.submitTenant = addTenant;
    }]
});


