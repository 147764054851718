const ngModule = angular.module('ppa.widgets.rent-card', [
    'ppa.services.snack'
]);

ngModule.directive('ppaRentCard', [function(){
    return {
        restrict: 'A',
        template: require('./rent-card.pug'),
        scope: {
            rent: '<ppaRentCard',
            onAddPayment: '&',
            onUpdate: '&',
            onDelete: '&',
            onDeletePayment: '&',
            onOpenDialog: '&',
            isEditing: '<'
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['$rootScope', '$element', 'snackService', function($rootScope, $element, snackService) {

            var vm = this;

            // -- util functions

            function showSuccess() {
                snackService.success('Payment saved');
            }

            function showError() {
                snackService.error('Payment was not saved');
            }

            // -- api

            vm.rentColor = function(percentage) {
                if (percentage === 1) {
                  return 'almost-paid';
                } else if (percentage > 0.49) {
                  return 'partially-paid';
                } else {
                  return "majority-not-paid";
                }
            };

            vm.addPayment = function() {
                if(vm.form.$valid) {
                    vm.processing = true;
                    vm.onAddPayment({
                        payment: vm.rent.payment,
                        rent: vm.rent._id,
                        property: vm.rent.property._id
                    })
                    .then(showSuccess, showError)
                    .finally(function(){
                        vm.processing = false;
                    });
                }
            };

            vm.updateRent = function(rent) {
                return vm.onUpdate({
                    rent: rent
                });
            };

            vm.deleteRent = function(id){
                return vm.onDelete({
                    id: id
                });
            };

            vm.deletePayment = function(id) {
                return vm.onDeletePayment({
                    id: id
                }).then(function(id){
                    return id;
                });
            };

            vm.openPaymentHistoryList = function() {
                var scope = $rootScope.$new();

                scope.payments = vm.rent.payments;
                scope.deletePayment = vm.deletePayment;

                var html = '<div ppa-payment-history-list="payments" on-delete="deletePayment(id)"></div>';

                return vm.onOpenDialog({
                    contents: html,
                    scope: scope,
                    element: $element
                });

          };

            vm.editRent = function() {
                var scope = $rootScope.$new();

                scope.rent = angular.copy(vm.rent);

                scope.onUpdate = vm.updateRent;
                scope.onDelete = vm.deleteRent;

                var html = '<div ppa-edit-rent-form="rent" on-update="onUpdate(rent)" on-delete="onDelete(id)"></div>';

                vm.onOpenDialog({
                    contents: html,
                    scope: scope,
                    element: $element
                });

            };

            vm.togglePaymentForm = function() {
                vm.formVisible = !vm.formVisible;
            };


            // -- main





        }]
    };
}]);
