const ngModule = angular.module('ppa.widgets.client-form', [
    'ppa.services.snack'
]);

ngModule.component('ppaClientForm', {
    template: require('./client-form.pug'),
    bindings: {
        client: '<',
        onSubmit: '&'
    },
    controllerAs: 'vm',
    controller: ['snackService', function(snackService) {

        var vm = this;

        vm.form = {};

        // -- api

        vm.submit = function() {
            if (vm.form.$valid) {
                vm.processing = true;

                vm.onSubmit({
                    client: vm.client
                })
                .finally(function(){
                    vm.processing = false;
                    vm.feedbackEnabled = false;
                    vm.form.$setPristine();
                });
            } else {
                vm.feedbackEnabled = true;
                snackService.error('Unable to save client');
            }
        };


    }]
});
